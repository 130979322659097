import React from "react";
// Customizable Area Start
import {
  Button,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  Paper,
  Icon,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { 
  createTheme, 
  styled, 
  ThemeProvider
 } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdownIcon from "../../../../packages/components/src/CustomDropdown";
const loginbg = require("../assets/loginbg.jpg");
import { leftBackground, group_active_step_1, group_active_step_2, filelogo, upload_icon, browse_icon, delete_icon } from "./assets";
import Dropzone, {DropzoneState} from 'react-dropzone';
import WorldFlag from 'react-world-flags';

const webStyles = { 
 
  boldText: {
    display: "inline-block",
    marginLeft: "5px",
    color: "rgba(35, 113, 130, 1)",
    fontWeight: "bold",
    verticalAlign: "middle",
  
  },
  labelText: {
    fontFamily: "Poppins",
    lineHeight: '22px',
    color: '#334155',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '14px',}as const,
  labelBrowse: {
    display: "inline-block",} as const,
  inlineIcon: {
    verticalAlign: "middle",
    color: "#237182",
    display: "inline-block",
    width:"20px"
  },
  fileUploadContainer: {
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between', },
  mainbox: {
    alignItems: "stretch",
    justifyContent: "stretch",
    backgroundColor: "rgba(50, 89, 98, 1)",
    display: 'flex',
    height: '100vh',
    padding: 0,},
  rightBox: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "24px",
    width: "50%",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    borderBottomLeftRadius: "24px",
    padding: "48px",
    height: "100vh",
    overflowY: "auto",} as const,
  leftbox: {
    padding: "40px",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
    height: "100vh",
    width: "50%",
    display: 'flex',} as const,
  logohereText: {
    color: "white",
    fontWeight: "bold",
    textAlign: 'left',
    fontSize: "30px", } as const,
  imgPic: {
    width:"70%",
  },
  labelContainer: {
    fontWeight: "600",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color:"#334155",
    marginRight: "20px",
    fontSize: "16px", },
  label: {
    verticalAlign: "middle",
    color: "#237182",
    cursor: 'pointer',
    gap: "5px",
    display: "inline-block",
    fontFamily: "Poppins"
  },
  fileInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  fileLogo: {
    width: '50px',
    height: '50px',
  }, 
};
const errorMessageStyles = {
  color: 'red',
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: '0.5rem',
  marginTop: '4px',
  fontFamily: "'Poppins'",
  position: "relative",
  left: "-6px",
};
const styles = {
  groupSection1: {
    display: "flex",
    alignItems: "center",
    flexDirection:"column" as "column",
    width:"100%",
    justifyContent:"center",
    minHeight:"85vh"
  },
  buttonContainer:{
      display: "flex",
      marginTop:"2.2rem"},
  group1: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    alignSelf: "stretch",
    width:"100%",
    gap: "32px",
    alignItems: "flex-start",} as const,
  groupTop: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    alignSelf: "stretch",
    display: "flex",
    gap: "24px",} as const,
  
  group2: {
    justifyContent: "center",
    alignSelf: "stretch",
    display: "flex",
    gap: "24px",
    alignItems: "center",
    flexDirection: "column"} as const,

  group3: {
    width:"100%",
    padding: "0 7px",
    fontSize: "16px", 
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",} as const,
  titleLabel: {
    letterSpacing: "normal",
    fontWeight: "700", 
    textAlign: "center",
    margin: 0,
    alignSelf: "stretch",
    fontFamily: "'Poppins",
    position: "relative",
    fontSize: "30px", 
    lineHeight: "40px",} as const,
  groupStep: {
    display: "flex",
    padding: "8px 25px",
    boxSizing: "border-box",
    minWidth: "195px",
    alignItems: "center",
    justifyContent: "flex-start", } as const,
  groupStep1: {
    justifyContent: "center",
    gap: "16px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    flexDirection: "row", } as const,
  groupStep2: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    minWidth: "195px",
    color: "#ffffff",
    flexDirection: "column",
    padding: "8px 25px",} as const,
  groupStep3: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    flexDirection: "row",
    justifyContent: "center",
    overflow: "hidden",} as const,
  groupActiveStep1: {
    height: "32px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 11px",
    boxSizing: "border-box",
    width: "32px",
    borderRadius: "90px",
    display: "flex",} as const,
 
  groupContent: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "16px",
    textAlign: "left",
    fontSize: "inherit",
    color: "#475569",
  } as const,
  textlabelTitle: {
    fontWeight: 600,
    color: "#64748B",
    position: "relative",
    textDecoration: "none",
    lineHeight: "24px",
    fontFamily: "'Poppins'",
    fontSize:"16px",
    
  } as const,
  group43: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    gap: "24px",
    marginTop:"-20px"
  } as const,
  group4: {
    width: "100%",
    display: "flex",
    gap: "24px",
    maxWidth: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop:"9px"
  } as const,

  group9: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "24px",
    maxWidth: "100%",
    marginTop:"10x"
  } as const,
  groupInputWithLabel: {
    flex: 1,
    color:"#334155",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",
  } as const,
  groupActiveStep: {
    height: "32px",
    width: "32px",
    borderRadius: "50%", 
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  } as const,
  imageIcon: {
    height: "24px",
    borderRadius: "50%", 
    objectFit: "cover", 
  } as const,

  groupInputWithLabelPN: {
    display: 'flex',
    width: '100%',
    gap:"16px",
    color:"#334155",
  } as const,
  groupPH: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection:"column"
  } as const,
  inputName: {
    lineHeight: "24px",
    fontWeight: 600,
    fontFamily: "Poppins",
    marginBottom:"10px"
    
  } as const,
  inputName1: {
    lineHeight: "24px",
    fontWeight: 600,
    fontFamily: "Poppins",
    visibility: "hidden"
  } as const,
  inputNameTP: {
    visibility: "hidden"
  } as const,
  inputNamePN: {
    fontFamily: 'Poppins', 
    fontWeight: 600, 
    fontSize: '16px', 
    lineHeight: '24px', 
    color: '#334155'
  } as const,

  submitButtonActive: {
    background: '#237182',
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight:600,
    textTransform: 'none',
    height: '56px',
    borderRadius: "8px",
    width: '100%',
  }as const,
  textFilled: {
    borderRadius: "8px",
    width: "100%",
    color:"#0F172A !important",
    height: "56px",
    backgroundColor: "#FFFFF",} as const,
  textinputBoxed: {
    backgroundColor: "#FCFDFE",
    borderRadius: "8px",
    width: "100%",
    height: "56px",
    color:"#0F172A !important"} as const,
  textinputBoxed2: {
    height: "56px",
    backgroundColor: "#FCFDFE",
    borderRadius: "8px",
    width: "100%",} as const,
  textinputBoxedCountryCode: {
    borderRadius: "8px",
    width: "100%",
    height: "56px",
    backgroundColor: "#FCFDFE", } as const,
  textinputBoxedCountryCodePN: {
    backgroundColor: "#FCFDFE",
    borderRadius: "8px",} as const,
  textinputBoxed3: {
    height: "56px",
    backgroundColor: "#FCFDFE",
    borderRadius: "8px", } as const,
  button: {
    flex: 1,
    minWidth: "193px",
    fontSize: "16px",
    background: "#F1F5",
    borderRadius: "8px",
    fontWeight:"600",
    fontFamily: "Poppins",
    textTransform: "none",
    color: "#64748b",
    "&:hover": { background: "#f1f5f9" },} as const,
  submitButton: {
    backgroundColor: '#E2E8F0',
    fontFamily: 'Poppins',
    fontWeight:600,
    textTransform: 'none',
    height: '56px',
    borderRadius: "8px",
    width: '100%',
    color: '#64748B',
    fontSize: '16px', }as const,
};
const inputStyles = {
  borderRadius: "8px",
  color: "#0F172A",
  fontFamily: "'Poppins'",};

const MainLeftText = styled(Typography)({
  textAlign: "center",
  color: "#FFF",
  lineHeight: "3.5rem",
  fontSize: "2.5rem",
  fontWeight: 700,
  margin: '0,5rem 2.25rem 0',
  '@media (max-height:768px)': {
    lineHeight: "3rem",
    fontSize: "2rem",
    marginTop: 0,
  },
  '@media (max-height:523px)': {
    lineHeight: "2.5rem",
    fontSize: "1.5rem",
  },
  '@media (max-height:363px)': {
    lineHeight: "2rem",
    fontSize: "1.25rem",
  },
})

const SecondaryLeftText = styled(Typography)({
    textAlign: "center",
    fontSize: "1.25rem",
    color: "#FFF",
    marginTop: "0.75rem",
    '@media (max-height:523px)': {
      fontSize: "1rem",
      marginTop: "0.5rem",
    },
    '@media (max-height:363px)': {
      fontSize: "0.75rem",
    },
});

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
      },
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins, sans-serif",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  configJSON
} from "./CustomisableUserProfilesController";
import Close from "@material-ui/icons/Close";
// Customizable Area End

export default class CustomisablePersonalProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderDropZone = () => {
    return (
      <>
      <Dropzone
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            this.updateCompanyLogo({ target: { files: [file] } as any });
          }
        }}
        data-test-id="dropzone"
      >
        {({ getRootProps, getInputProps }: DropzoneState) => (
          <Paper
            elevation={0}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              height: this.state.imageData.file_name ? "65px" : "144px",
              borderRadius: "2px",
              padding: this.state.imageData.file_name ? "4px 8px" : "16px 24px",
              backgroundColor: this.state.imageData.file_name ? "white" : "#F8FAFC",
              border: this.state.imageData.file_name ? "none" : "2px dashed rgba(203, 213, 225, 1)",
              boxSizing: "border-box",
              margin: "0 auto",
              marginTop: this.state.imageData.file_name ? "4px" : "10px",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {!this.state.imageData.file_name ? (
              <>
                <Typography variant="h5">
                  <Icon>
                    <img src={upload_icon} alt="Upload Icon" />
                  </Icon>
                </Typography>
                <label htmlFor="contained-button-file" style={{ ...webStyles.label, color: '#334155' }}>
                  <Typography variant="body1"><strong>Upload your files here</strong></Typography>
                </label>
                <Typography variant="body1">
                  <span style={webStyles.labelText}>Drag & Drop the files here or</span>
                  <strong style={webStyles.boldText}>
                    <label htmlFor="contained-button-file" style={webStyles.label}>
                      <img src={browse_icon} alt="Upload Icon" style={webStyles.inlineIcon} /> <span style={webStyles.labelBrowse}>Browse</span>
                    </label>
                  </strong>
                </Typography>
                {this.state.companyLogoError && (
                  <Typography variant="body2" color="error">
                    {this.state.companyLogoErrorMsg}
                  </Typography>
                )}
              </>
            ) : (
              <Box style={webStyles.fileUploadContainer} data-test-id="uploadedFile">
                <Box style={webStyles.fileInfoContainer}>
                  <img data-test-id="trigger-dialog" onClick={(event) => {
                      event.stopPropagation(); 
                      this.handleOpenDialog();
                    }} src={this.state.companyLogo} alt="Company Logo" style={webStyles.fileLogo} />
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1">{this.state.imageData.file_name}</Typography>
                    <Typography variant="body2">{this.renderFileSize(this.state.imageData.size)}</Typography>
                  </Box>
                </Box>
                <Button onClick={(event) => {
                 event.stopPropagation();
                 this.handleDelete();
                 }} data-test-id="btnfileDelete">
                  <img src={delete_icon} alt="delete" style={{ width: "24px", height: "24px" }} />
                </Button>
              </Box>
            )}
          </Paper>
        )}
      </Dropzone>
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <Box style={{
          display:"flex",
          justifyContent:"space-between",
          alignContent:"center",

        }}>
        <DialogTitle>Preview Logo</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleCloseDialog} color="primary">
        <Close/></Button></DialogActions>
        </Box>
       
        <DialogContent>
          {this.state.companyLogo && (
            <img
              src={this.state.companyLogo}
              alt="Company Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </DialogContent>
      </Dialog>
      </>
    );
  }
  render() {
    // Customizable Area Start
    const { formComplete, companyNameError, companyNameErrorMsg,phoneNumberError,phoneNumberErrorMsg,lastNameError,lastNameErrorMsg,firstNameError,firstNameErrorMsg,countryCode,countryCodeError,aboutMeError,aboutMeErrorMsg } = this.state;
    const handleAboutInfoUi=()=>{
      return (
        <Box style={styles.group9}>
                {this.state.role!=="owner"?
                    <Box style={styles.groupInputWithLabel}>
                    <Typography style={styles.inputName}>
                      {configJSON.aboutmeTxt}
                    </Typography>
                    <TextField
                      fullWidth
                      style={styles.textinputBoxed2}
                      data-test-id="txtInputAboutMe"
                      placeholder="Write something about yourself..."
                      variant="outlined"
                      name="aboutMe"
                      value={this.state.aboutMe}
                      onChange={this.updateAboutMe}
                      error={aboutMeError}
                      helperText={this.getHelperText(aboutMeError, aboutMeErrorMsg)}
                      InputProps={{ style: inputStyles }}
                      FormHelperTextProps={{ style: errorMessageStyles }as any } 
                    />
                  
                  </Box>
                :
                <Box style={styles.groupInputWithLabel}>
                  <Typography style={styles.inputName}>
                    Company Name *
                  </Typography>
                  <TextField
                    fullWidth
                    style={styles.textinputBoxed2}
                    data-test-id="txtInputCompanyName"
                    placeholder="Enter company name"
                    variant="outlined"
                    name="companyName"
                    value={this.state.companyName}
                    onChange={this.updateCompanyName}
                    error={companyNameError}
                    helperText={this.getHelperText(companyNameError, companyNameErrorMsg)}
                    InputProps={{ style: inputStyles }}
                    FormHelperTextProps={{ style: errorMessageStyles }as any} 
                  />
                
                </Box>
                }
              </Box>
      )
    }
    const handleStatus = () => {
      const { firstNameError, lastNameError, phoneNumberError, countryCodeError, aboutMeError,phoneNumber,first_name,lastName,aboutMe, } = this.state;
      return (firstNameError || lastNameError || phoneNumberError || countryCodeError || aboutMeError||phoneNumber===""||first_name===""||lastName===""||aboutMe==="");
    };
    
    return (
      <ThemeProvider theme={theme}>
          
          <Grid container style={webStyles.mainbox} >
          <Grid item md={6} sm={12} xs={12} style={{
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              boxSizing: "border-box",
              textAlign: "center",
              padding: "48px",
              width: "50%",
              backgroundImage: `url(${loginbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100vh",
           
            }}>
          <Box style={{
            marginBottom:"10px",
            width:"100%"
          }}>
            <Typography style={webStyles.logohereText}>
              Logo Here
            </Typography>
            <img src={leftBackground} style={webStyles.imgPic} />
            <Box style={{
              alignItems:"center",
              display:"flex",
              flexDirection:"column",
            }} paddingX={5}>
              <MainLeftText>
                Your new construction <br/> management tool.
              </MainLeftText>
              <SecondaryLeftText>
                Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit. Sed non faucibus nunc. Pellentesque.
              </SecondaryLeftText>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12} data-test-id='oKay' style={webStyles.rightBox} >
        <Box style={styles.groupSection1}>
        <Box style={styles.group1}>
            <Box style={styles.groupTop}>
              <Box style={styles.group2}>
                <Typography style={styles.titleLabel}>
                  Create your profile
                </Typography>
                {this.state.role==="owner"&&<Box style={styles.group3}>
                  <Box style={styles.groupStep}>
                
                    <Box style={styles.groupStep1}>
                      <Box style={styles.groupActiveStep}>
                        <img
                          style={styles.imageIcon}                    
                          alt=""
                          src={group_active_step_1}
                        />
                      </Box>
                      <Typography style={styles.textlabelTitle}>
                        Personal Information
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={styles.groupStep2}>
                   
                    <Box style={styles.groupStep3}>
                      <Box style={styles.groupActiveStep1}>
                        <Typography >
                        <img
                          style={styles.imageIcon}                    
                          alt=""
                          src={group_active_step_2}
                        />
                        </Typography>
                      </Box>
                      <Typography style={styles.textlabelTitle}>
                        Business Information
                      </Typography></Box>
                  </Box>
                   </Box>}
              </Box></Box>
            <Box style={styles.groupContent} 
            component="form" >
              <Box style={styles.group43}> <Box style={styles.groupInputWithLabel}>
                  <Typography style={styles.inputName}>
                    First Name * </Typography>
                  <TextField style={styles.textinputBoxed}
                    data-test-id="txtInputFirstName"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.updateFirstName}
                    error={firstNameError}
                    placeholder="Enter first name"
                    variant="outlined"
                    helperText={this.getHelperText(firstNameError, firstNameErrorMsg)}
                    InputProps={{ style: inputStyles }}
                    FormHelperTextProps={{ style: errorMessageStyles}as any} />
                </Box><Box style={styles.groupInputWithLabel}>
                  <Typography style={styles.inputName}>Last Name *
                  </Typography>
                  <TextField
                    name="lastName"
                    onChange={this.updateLastName}
                    style={styles.textinputBoxed}
                    data-test-id="txtInputLastName"
                    value={this.state.lastName}
                    placeholder="Enter last name"
                    variant="outlined"
                    error={lastNameError}
                    helperText={this.getHelperText(lastNameError, lastNameErrorMsg)}
                    InputProps={{ style: inputStyles }}
                    FormHelperTextProps={{ style: errorMessageStyles } as any}  /> </Box>
              </Box> <Box style={styles.groupPH}>
              <Box style={{ ...styles.groupInputWithLabelPN, width: "100%" }}>
                <Typography style={styles.inputName}>Phone Number *</Typography>
      
              </Box>
              <Box style={{ ...styles.groupInputWithLabelPN, width: "100%" }}>
              <Select
                  style={{ ...styles.textinputBoxedCountryCodePN, width:'151px', height:'55px' }}
                  data-test-id="txtInputCountryCode"
                  name="countryCode"
                  value={`+${countryCode}`}
                  error={countryCodeError}
                  displayEmpty
                  IconComponent={CustomDropdownIcon}
                  variant="outlined"
                  onChange={this.updateCountryCode}

                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        
                       
                        height:"290px",
                        width:"20px",
                        borderRadius:"8px",
                        boxShadow:"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
                      
                      },
                    },
                  }}
                  inputProps={{
                    onFocus: (e) => {
                      e.target.style.backgroundColor ='transparent'; 
                  },
                    style: {
                        backgroundColor: 'transparent',}, }}
                >
                  {configJSON.countryCodes.map((country: { dial_code: {} | null | undefined; code: React.Key | null | undefined; }) => (
                    <MenuItem value={country.dial_code as string} key={country.code}>
                      <Box display="flex" alignItems="center" style={{gap:"5px"}}>
                      <WorldFlag code={country.code as string}  height="18px" width="24px" />
                        <Typography style={{
                        marginTop:"2px" ,
                        fontSize: '16px',
                        fontFamily: 'Poppins',
                        fontWeight: 400
                      }} >&nbsp;{country.dial_code}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
           
                <TextField
                fullWidth
                  style={styles.textinputBoxed3}
                  data-test-id="txtInpuPhoneNumber"
                  placeholder="00000 00000"
                  variant="outlined"
                  name="phoneNumber"
                  type="tel"
                  value={this.state.phoneNumber}
                  InputProps={{ style: { ...styles.textinputBoxed3 },  inputProps: {
                    pattern: "[0-9]*",
                    inputMode: "numeric"
                  } }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();}
                  }}
                  onChange={this.updatePhoneNumber}
                  error={phoneNumberError}
                  helperText={this.getHelperText(phoneNumberError, phoneNumberErrorMsg)}
                  FormHelperTextProps={{ style: errorMessageStyles as any }} 
                />
                  
              </Box>
            </Box>
              {handleAboutInfoUi()}
            </Box>

           {this.state.role==="owner"&& <Box display="flex" gridGap={10} justifyContent="space-between" alignItems="center" width="100%">
              <Box width={"100%"} mt={-3.7}>
                <Box sx={{ position: 'relative', display: 'inline-block' }}>
                  <Typography data-test-id="logoText" variant="body1" component="span" style={webStyles.labelContainer}>
                    Company Logo *
                  </Typography>
                </Box>
                
                {this.renderDropZone()}

              </Box>
           </Box>}
            
          
          </Box>
          <Box style={styles.buttonContainer}  display="flex" gridGap={10} justifyContent="space-between" alignItems="center" width="100%">
              {this.state.role!=="owner"?
              <Button
              data-test-id="submitBtn"
              type="button"
              onClick={this.createPersonalProfiles}
              style={!handleStatus() ? styles.submitButtonActive : styles.submitButton}
              disabled={handleStatus()}
              fullWidth
              >
              Next
            </Button>
              :
              <Button
                data-test-id="submitBtn"
                type="button"
                onClick={this.createPersonalProfiles}
                style={formComplete ? styles.submitButtonActive : styles.submitButton}
                disabled={!formComplete}
                fullWidth
                >
                Next
              </Button>
              }
            </Box>
         
      </Box>
        </Grid>
          </Grid>


      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End

import React from "react";

// Customizable Area Start
import { Box, Typography,Button, Modal } from "@material-ui/core";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { tickRight, crossIcon } from "./assets";
import MessageBox from "../../../components/src/messageBox";
import Loader from "../../../../packages/components/src/Loader.web";
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON,
} from "./CustomisableusersubscriptionsController.web";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPlanTitle=(name:string)=>{
   switch(true){
    case name.toLowerCase().includes("year"):return "Annual Plan";
    case name.toLowerCase().includes("quarter"):return "Quarterly Plan";
    case name.toLowerCase().includes("month"):return "Monthly Plan";
    default :return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();;
   }
  };
  renderFormatDate = (valid_up_to: string): string => {
    const date = new Date(valid_up_to);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };
  renderActiveCard=(id:string,name:string,status:string,valid_up_to:string,price:string)=>{
   return( <Box style={styles.detailsBox} key={id}>
    <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography style={styles.fontStyles("16px",700,"#1E293B")}>{this.renderPlanTitle(name)}</Typography>
    <Typography style={styles.fontStyles("16px",600,"#1E293B")}>{configJSON.status}<Box component={"span"} style={{...styles.toggleBtn("#059669","#D1FAE5"),textTransform:"uppercase"}}>{status}</Box></Typography>
     </Box>
     <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography style={styles.fontStyles("16px",400,"#475569")}>{configJSON.professional}</Typography>
    <Typography style={styles.fontStyles("16px",600,"#1E293B")}>{configJSON.renew}<Box component={"span"} style={styles.fontStyles("16px",400,"#475569")}>{this.renderFormatDate(valid_up_to)}</Box></Typography>
     </Box>
     <Box style={{display:"flex",flexDirection:"column",gap:"12px"}} >
        <Box style={styles.validationText}>
          <img src={tickRight} width={"16px"} height={"16px"}/>
          <Typography style={styles.fontStyles("16px",400,"#475569")}>{configJSON.textOne}</Typography>
        </Box>
        <Box style={styles.validationText}>
         <img src={tickRight} width={"16px"} height={"16px"}/> 
          <Typography style={styles.fontStyles("16px",400,"#475569")}>{configJSON.textTwo}</Typography>
        </Box>
        <Box style={styles.validationText}>
          <img src={tickRight} width={"16px"} height={"16px"}/> 
          <Typography style={styles.fontStyles("16px",400,"#475569")}>{configJSON.textThree}</Typography>
        </Box>
      </Box>
      <Box style={{display:"flex",alignItems:"center"}}>
     <Typography style={styles.fontStyles("30px",700,"#334155")}>£{Math.floor(Number(price))} </Typography>
     <Typography style={styles.fontStyles("16px",400,"#475569")}> /{name}</Typography>
      </Box>
    </Box>);
  }
  renderCancelCards=()=>{
    return (this.state.subscriptions.length>0&&
      this.state.subscriptions.map((item)=>(
        (item.attributes.status.toLowerCase()==="active"&&!item.attributes.expired)?
        this.renderActiveCard(item.id,item.attributes.name,item.attributes.status,item.attributes.valid_up_to,item.attributes.price)
      :<Box style={styles.canceledContainer} key={item.id}>
      <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
      <Typography style={styles.fontStyles("16px",700,"#1E293B")}>{this.renderPlanTitle(item.attributes.name)}</Typography>
      <Typography style={styles.fontStyles("16px",600,"#1E293B")}>{configJSON.status} <Box component={"span"} style={{...styles.toggleBtn("#DC2626","#FEE2E2"),textTransform:"uppercase"}}>{item.attributes.expired?"EXPIRED":"CANCELLED"}</Box></Typography>
       </Box>
      <Typography style={styles.fontStyles("16px",400,"#475569")}>{item.attributes.expired?configJSON.expired:configJSON.endDate} {this.renderFormatDate(item.attributes.valid_up_to)}</Typography>
      </Box>
      )))
      
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
    <MyProfileHeader navigation={this.props.navigation} showSidebar={true}>
    {this.state.tostifymessage.show && <MessageBox message={this.state.tostifymessage.message} status={this.state.tostifymessage.status}/>}
      <Box style={styles.mainContainer}>
       <Typography style={styles.fontStyles("24px",700)}>{configJSON.subscriptionPricing}</Typography>
        {this.state.isLoading?<Loader loading/>:<></>}
        <Box style={styles.detailsContainer }>
          {this.state.currentPlan.attributes.name&&
          this.renderActiveCard(this.state.currentPlan.id,this.state.currentPlan.attributes.name,this.state.currentPlan.attributes.status,this.state.currentPlan.attributes.valid_up_to,this.state.currentPlan.attributes.price)
          }
          {this.renderCancelCards()}
          {
           (this.state.subscriptions.length===0&&!this.state.currentPlan.attributes.name)&&<Typography>No data</Typography>
          }
          <Box style={{display:"flex"}}>
          <Button data-test-id="cancellationBtn" disabled={!this.state.currentPlan.id} style={{...styles.cancelBtn("#E0EDF0","#325962"),textTransform: "capitalize"}} onClick={()=>this.cancellationModalOpen()}>{configJSON.cancelSubscription}</Button>
          <Button data-test-id="changeBtn" style={{...styles.cancelBtn("#237182","white"),marginLeft:"auto",textTransform: "capitalize"}} 
          onClick={()=>this.handleNavigation("SubscriptionList")}
          >{configJSON.changeSubscription}</Button>
          </Box>
        </Box>
      </Box>
      <Modal data-test-id="modal" open={this.state.openCancel} >
       <Box style={styles.modalPaper}>
       <Box style={{display:"flex"}}>
        <Typography style={{...styles.fontStyles("24px",700,"#0F172A"),marginRight:"auto"}}>Are you sure you want to cancel your subscription?</Typography>
        <img data-test-id="closeModal" src={crossIcon} style={styles.crossStyles} onClick={()=>this.cancellationModalClose()}/>
       </Box>
       <Typography data-test-id="modalText" style={{...styles.fontStyles("16px",400,"#1E293B"),marginTop:"16px"}}>
        {configJSON.cancellationModalTxt}
       </Typography>
       <Box style={{display:"flex",gap:"16px",marginTop:"40px"}}>
        <Button data-test-id="backModalBtn" style={{...styles.modalBtn("#E0EDF0","#325962"),marginLeft:"auto",textTransform:"capitalize"}} onClick={()=>this.cancellationModalClose()}>{configJSON.back}</Button>
        <Button data-test-id="confirmPlan" style={{...styles.modalBtn("#237182","white"),textTransform:"capitalize"}} onClick={()=>this.confirmCancellation()}>{configJSON.confirm}</Button>
       </Box>
       </Box>
      </Modal>
    </MyProfileHeader>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles={
  mainContainer:{
    boxSizing:"border-box",
    padding: "24px 48px 24px 48px",
    fontFamily: "Poppins",
    marginLeft:"6rem",
    display:"flex",
    flexDirection:"column",
    gap:"2rem",
    backgroundColor:"#f0f5f7",
    height:"calc(100vh - 79px)",
    overflowY:"auto",
    "-webkit-scrollbar": { 
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  } as const,
  detailsContainer:{
    display:"flex",
    flexDirection:"column",
    gap:"1.5rem",
    backgroundColor:"#FFFFFF",
    padding:"25px",
    borderRadius:"18px",
  }as const,
  detailsBox:{
    border:"1px solid #CBD5E1",
    padding:"20px",
    borderRadius:"10px",
    display:"flex",
    flexDirection:"column",
    gap:"20px",
  }as const,
  canceledContainer:{
    border:"1px solid #CBD5E1",
    backgroundColor:"#f0f5f7",
    padding:"20px",
    borderRadius:"10px",
    display:"flex",
    flexDirection:"column",
    gap:"20px",
  }as const,
  cancelBtn:(color:string,colorF:string)=>{return{
    backgroundColor:color,
    color:colorF,
    padding:"10px 15px",
    borderRadius:"9px",
    width:"fit-content",
    fontSize:"16px",
    fontFamily:"Poppins",
    fontWeight:600,
    cursor:"pointer",
  }},
  validationText:{
    display:"flex",
    alignItems:"center",
    gap:"8px",
  },
  fontStyles:(size:string,weight:number,color:string="black")=>{
    return {
     fontFamily:"Poppins",
     fontWeight:weight,
     fontSize:size,
     color:color
    }
  },
  toggleBtn:(color:string,bgcolor:string)=>{
    return{
      backgroundColor:bgcolor,
      borderRadius:"40px",
      padding:"2px 8px",
      ...styles.fontStyles("12px",700,color)
    }
  },
  modalBtn:(color:string,colorF:string)=>{return{
    backgroundColor:color,
    color:colorF,
    padding:"16px",
    borderRadius:"9px",
    fontSize:"16px",
    fontFamily:"Poppins",
    fontWeight:600,
    cursor:"pointer"
  }},
  modalPaper:{
    position: 'absolute',
    width:"580px",
    backgroundColor: "#fff",
    padding: "40px 32",
    borderRadius:"16px",
    top:"50%",
    left:"50%",
    transform:"translate(-50%,-50%)"
  } as const,
  crossStyles:{
    cursor:"pointer",
    width:"20px",
    height:"20px"
  }as const
}
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
interface Proposal {
  "id": string,
  "type": string,
  "attributes": {
    "title": string,
    "description": string,
    "price": number,
    "date_of_proposal": string,
    "customer_emails": string[],
    "name_of_proposal_creator": string,
    "customer_name": string

  }
}
export interface ProposalType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    price: number;
    date_of_proposal: string;
    customer_emails: string[];
    name_of_proposal_creator: string;
    customer_name: string;
  };
}

export interface DateObject {
  day: number;
  dateString: string;
  month: number;
  timestamp: number;
  year: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openCreateForm: boolean,
  title: string,
  description: string,
  price: string,
  dateOfProposal: string,
  customerName: string,
  customerEmail: string,
  creator: string,
  listOfProposals: Proposal[],
  deleteID: string,
  proposalIndex: number,
  deleteConfirmationPopup: boolean,
  openCalendarPicker: boolean,
  shareActivated: boolean,
  numberOfCustomers: number,
  customersArray: string[],
  showValidateMessage: boolean,
  mode: string,
  shareProposalID: string,
  selectedDate: Date | null,
  proposalDate: null | MaterialUiPickersDate,
  viewproposal: null | Proposal,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Proposalgeneration2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProposalsAPICallID: string = "";
  createProposalAPICallID: string = "";
  deleteProposalAPICallID: string = "";
  shareProposalAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openCreateForm: false,
      title: '',
      description: '',
      price: '',
      dateOfProposal: '',
      customerName: '',
      customerEmail: '',
      creator: '',
      listOfProposals: [],
      deleteID: '',
      proposalIndex: -1,
      deleteConfirmationPopup: false,
      openCalendarPicker: false,
      shareActivated: false,
      numberOfCustomers: 1,
      customersArray: ["0"],
      showValidateMessage: false,
      mode: 'add',
      shareProposalID: "",
      selectedDate: new Date(),
      proposalDate: null,
      viewproposal: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
      return false
    }

    if (response.errors) {
      this.parseApiCatchErrorResponse(response)
      return false
    }
    if (requestCallId === this.getProposalsAPICallID) {
      this.setState({ listOfProposals: response.data ? response.data : [] })
    } else if (requestCallId === this.createProposalAPICallID) {
      this.showAlert("", "Proposal Saved Successfully");
      this.handleSuccessResponse()
    } else if (requestCallId === this.deleteProposalAPICallID) {
      this.handleSuccessResponse()
    } else if (requestCallId === this.shareProposalAPICallID) {


      alert(response.message)
      this.setState({ viewproposal: null });

      this.closePopUp()
    }
    // Customizable Area End
  }

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  setTitle = (text: string) => {
    this.setState({
      title: text
    })
  }

  setDescription = (text: string) => {
    this.setState({
      description: text
    })
  }

  setPrice = (text: string) => {
    this.setState({
      price: text
    })
  }

  setDateOfProposal = (dayObject: DateObject) => {
    this.setState({
      dateOfProposal: dayObject.day + "/" + dayObject.month + "/" + dayObject.year,
      openCalendarPicker: false
    })
  }
  setProposalDate = (dayObject: MaterialUiPickersDate) => {
    this.setState({
      proposalDate: dayObject
    })
  }
  setCustomerName = (text: string) => {
    this.setState({
      customerName: text
    })
  }



  setCustomerMails = (text: string, index: number) => {
    let mailArray = this.state.customerEmail.split(',');
    mailArray[index] = text
    this.setState({ customerEmail: mailArray.toString() })
  }

  showAlertMessage = (type: string) => {

    if (this.state.showValidateMessage) {
      if (type === 'title' && this.isStringNullOrBlank(this.state.title)) {
        return "Please enter title of the proposal";
      } else if (type === 'price' && this.isStringNullOrBlank(this.state.price)) {
        return "Please enter price of the proposal";
      }
      else if (type === 'price' && !(/^[0-9]+$/.test(this.state.price))) {
        return "Price should be number";
      }
      else if (type === 'date' && this.isStringNullOrBlank(this.state.dateOfProposal) || type === 'date' && !!this.state.proposalDate) {
        return "Please enter date of the proposal";
      } else if (type === 'desc' && this.isStringNullOrBlank(this.state.description)) {
        return "Please enter description of the proposal";
      } else if (type === 'creator' && this.isStringNullOrBlank(this.state.creator)) {
        return "Please enter creator of the proposal";
      } else {
        return ""
      }

    } else {
      return ""
    }
  }



  plus = () => {
    let array = [...this.state.customersArray]
    array.push(this.state.numberOfCustomers + "");
    this.setState({
      customersArray: array,
      numberOfCustomers: this.state.numberOfCustomers + 1,
      customerEmail: this.state.customerEmail + ","
    })
  }

  isStringNullOrBlank(text: string) {
    return text === null || text.trim().length === 0;
  }

  handleDataSaveClick = async () => {
    this.setState({ showValidateMessage: false });
    let isDate = this.state.proposalDate === null ? this.isStringNullOrBlank(this.state.dateOfProposal) : !this.state.proposalDate
    if (this.isStringNullOrBlank(this.state.title) || isDate ||
      this.isStringNullOrBlank(this.state.price) ||
      this.isStringNullOrBlank(this.state.description) ||
      this.isStringNullOrBlank(this.state.creator)) {
      this.setState({ showValidateMessage: true })
      return
    }
    let date = !!this.state.dateOfProposal ? this.state.dateOfProposal : moment(this.state.proposalDate).format("DD/MM/YYYY")

    let body = {
      title: this.state.title,
      description: this.state.description,
      price: this.state.price,
      date_of_proposal: date,
      name_of_proposal_creator: this.state.creator,
      customer_name: this.state.customerName
    };

    this.createProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: this.state.mode === 'add' ? configJSON.postMethod : configJSON.updateMethod,
      endPoint: this.state.mode === 'add' ? configJSON.proposalEndpoint : configJSON.proposalEndpoint + '/' + this.state.mode,
      body: JSON.stringify(body)
    });
  }
  handleSaveClick = async () => {
    this.setState({ showValidateMessage: false })
    if (this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.dateOfProposal) ||
      this.isStringNullOrBlank(this.state.price) ||
      this.isStringNullOrBlank(this.state.description) ||
      this.isStringNullOrBlank(this.state.creator)) {
      this.setState({ showValidateMessage: true })
      return
    }

    const formData = new FormData();
    formData.append("title", this.state.title)
    formData.append("price", this.state.price)
    formData.append("name_of_proposal_creator", this.state.creator)
    formData.append("date_of_proposal", this.state.dateOfProposal)
    formData.append("description", this.state.description)
    this.state.customerName != '' && formData.append("customer_name", this.state.customerName)
    formData.append("customer_emails", '[]')
    this.createProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: this.state.mode === 'add' ? configJSON.postMethod : configJSON.updateMethod,
      endPoint: this.state.mode === 'add' ? configJSON.proposalEndpoint : configJSON.proposalEndpoint + '/' + this.state.mode,
      body: JSON.stringify(formData)
    });

  }

  getMailValue = (index: number) => {
    return this.state.customerEmail.split(',')[index]
  }

  setCreator = (text: string) => {
    this.setState({
      creator: text
    })
  }

  toggleCreateForm = () => {
    this.setState({
      mode: 'add',
      openCreateForm: true
    })

  }

  shareProposal = (proposal: Proposal) => {
    this.setState({
      title: proposal.attributes.title,
      price: proposal.attributes.price + "",
      description: proposal.attributes.description,
      creator: proposal.attributes.name_of_proposal_creator,
      dateOfProposal: proposal.attributes.date_of_proposal,
      proposalDate: new Date(moment(proposal.attributes.date_of_proposal, "DD/MM/YYYY").toDate()),
      customerName: proposal.attributes.customer_name,
      shareProposalID: proposal.id,
      shareActivated: true
    })
  }

  shareProposalToMail = async () => {
    if (this.isStringNullOrBlank(this.state.customerEmail.split(',').join(''))) {
      this.showAlert("Alert", "Please enter customer mail to share")
      return false
    }

    let mailArray = this.state.customerEmail.split(',').filter(item => item !== "");;
    let shareArray: string = '[';
    let validate = true;
    mailArray.forEach(element => {
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(element) === false) {
        validate = false
      } else {
        shareArray = shareArray + '"' + element + '",'
      }
    });

    if (!validate) {
      this.showAlert("Alert", "Please enter valid mail ID to share")
      return false
    }

    const formData = new FormData();
    formData.append("proposal_id", this.state.viewproposal ? this.state.viewproposal?.id : "")
    formData.append('customer_emails', shareArray + ']');


    this.shareProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: configJSON.postMethod,
      endPoint: configJSON.shareProposalEndpoint,
      body: JSON.stringify(formData)
    });
  }

  editProposals = (proposal: Proposal) => {
    this.setState({
      title: proposal.attributes.title,
      price: proposal.attributes.price + "",
      description: proposal.attributes.description,
      creator: proposal.attributes.name_of_proposal_creator,
      dateOfProposal: proposal.attributes.date_of_proposal,
      proposalDate: new Date(moment(proposal.attributes.date_of_proposal.toString(), "DD/MM/YYYY").toDate()),
      customerName: proposal.attributes.customer_name || '',
      mode: proposal.id,
      openCreateForm: true
    })
  }
  shareModalFun = (selected: null | Proposal) => {
    this.setState({
      viewproposal: selected
    })
  }
  handleConfirmProposalEmail = () => {

  }
  shareProposalToMailWeb = async () => {

    if (this.isStringNullOrBlank(this.state.customerEmail.split(',').join(''))) {
      this.showAlert("Alert", "Please enter customer mail to share")
      return false
    }

    let mailArray = this.state.customerEmail.split(',').filter(item => item !== "");;
    let shareArray: string = '[';
    let validate = true;
    mailArray.forEach(element => {
      if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(element) === false) {
        validate = false
      } else {
        shareArray = shareArray + '"' + element + '",'
      }
    });

    if (!validate) {
      this.setState({
        viewproposal: null
      })
      this.showAlert("Alert", "Please enter valid mail ID to share");

      return false
    }

    this.shareProposalAPICallID = await this.apiCall({
      contentType: configJSON.formDataContent,
      method: configJSON.postMethod,
      endPoint: configJSON.shareProposalEndpoint,
      body: JSON.stringify({
        proposal_id: this.state.viewproposal ? this.state.viewproposal?.id : "",
        customer_emails: shareArray + ']'
      })
    });
  }
  openDeletePopup = (proposalID: string) => {
    this.setState({
      deleteID: proposalID + '',
      deleteConfirmationPopup: true
    })
  }

  handleTitle = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ title: event.target.value })

  }
  handlePrice = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ price: event.target.value })

  }
  handleEmail = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ customerEmail: event.target.value })
  }
  handleCreator = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ creator: event.target.value })

  }
  handleDescription = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ description: event.target.value })
  }
  handleCustomerName = (event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ customerName: event.target.value })

  }
  deleteHandler = async () => {
    this.deleteProposalAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteMethod,
      endPoint: configJSON.proposalEndpoint + "/" + this.state.deleteID,
      body: null
    });
  }


  closePopUp = () => {
    this.setState({
      deleteConfirmationPopup: false,
      openCreateForm: false,
      title: "",
      price: "",
      description: "",
      creator: "",
      dateOfProposal: "",
      proposalDate: null,
      proposalIndex: -1,
      shareActivated: false,
      openCalendarPicker: false,
      numberOfCustomers: 1,
      customersArray: ["0"],
      customerEmail: '',
      showValidateMessage: false,
      shareProposalID: ""
    })
  }

  toggleCalendar = () => {
    this.hideKeyboard();
    this.setState({
      openCalendarPicker: !this.state.openCalendarPicker
    })
  }



  getProposals = async () => {
    this.getProposalsAPICallID = await this.apiCall({
      contentType: configJSON.validationApiContentType + '',
      method: configJSON.validationApiMethodType + '',
      endPoint: configJSON.proposalEndpoint + '',
      body: null
    });
  }

  apiCall = async (data: {
    contentType: string, method: string, endPoint: string, body: string | null
  }) => {
    const { contentType, method, endPoint, body } = data;
    const proposalRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": contentType,
      })
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body && proposalRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(proposalRequestMessage.id, proposalRequestMessage);
    return proposalRequestMessage.messageId;
  };

  handleSuccessResponse = () => {
    this.closePopUp();
    this.getProposals()
    return true
  }

  async componentDidMount() {
    this.getProposals()
  }

  // Customizable Area End
}

import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start

// Customizable Area End


// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}

// Customizable Area End
export interface Props2 {
  navigation: any;
  id: string;
  // Customizable Area Start
  channalid: number; 
  reRenderCom:number;
  sendDataToParent:Function;


  // Customizable Area End
}


interface S {
  // Customizable Area Start
  searchInputText:string
  projectList:any[]
  channalMembertList:any[]
  channalid: number;
  reRenderCom: number;
  memberid:number;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatMemberController extends BlockComponent<Props2, S, SS> {
  // Customizable Area Start
  getMembersListCallId: string = "";
  // Customizable Area End

  constructor(props: Props2) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchInputText:'',
      projectList:[],
      channalid:0,
      reRenderCom:0,
      channalMembertList:[],
      memberid:0

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    console.log("111== mount",);
    const { channalid,  } = this.props;
    if (channalid > 0) {
      this.setState({ channalid }, () => {
        this.getMembersListApi();
      });
    }
    // Customizable Area End
  }
  async componentDidUpdate(prevProps: Props2) {
    if (prevProps.channalid !== this.props.channalid && this.props.channalid > 0 ) {
      this.setState({ channalid: this.props.channalid }, () => {
        this.getMembersListApi();

      });
    }
    if (prevProps.reRenderCom !== this.props.reRenderCom && this.props.reRenderCom > 0 ) {
      this.setState({ reRenderCom: this.props.reRenderCom }, () => {
        this.getMembersListApi();

        
      });
   
    }
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (responseJson) {
      this.parseApiErrorResponse(responseJson);
    }
    if (this.getMembersListCallId === apiRequestCallId) {
      
      
        if(!responseJson.errors) {
          console.log("11111111==== member list====>", responseJson);
          
          this.handleProjectListResponse(responseJson)
        }
    }
  }

  handleProjectListResponse = (res:any)=>{
    if(!res.data) {
      this.setState({channalMembertList: []})
    }else
       this.setState({channalMembertList:res?.data})
  }
  
  
  getMembersListApi = async () => {

    const token = await getStorageData("token")
    const header = {
      "Content-Type": "application/json",
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMembersListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chat/chat_memberships?chat_channel_id=${this.props.channalid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
 
  removemember = (remove_id: any) => {
    const flag = true; 
    const data ={
      remove_id:remove_id,
      flag:true,
    }
    this.props.sendDataToParent(remove_id, data); 
  };
  // Customizable Area End
}

import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Link,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
const closeImg = require("../assets/close.png");
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
} from "./BulkUploadingController";

export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={StyleSheet.screen}>
        <Box style={StyleSheet.imgModel as React.CSSProperties}>
         <img src={closeImg} data-test-id="closeImgAndRedirect" onClick={this.closeImgAndRedirect} style={StyleSheet.closeImg as React.CSSProperties} width={"50px"} height={"50px"} />
         <img src={this.state.profileImage} width={"100%"} height={"100%"} />
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleSheet = {
  screen:{
    width:"100vw",
    height:"100vh",
    backgroundColor:"rgba(0, 0, 0, 0.5)",
  },
  imgModel:{
    width:"40%",
    height:"80%",
    border:"2px solid black",
    margin:"80px auto 0px auto",
    position:"relative",
  },
  closeImg:{
    position:"absolute",
    top:"-50px",
    right:"-50px",
    cursor: "pointer"
  }
}
// Customizable Area End

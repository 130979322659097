Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign Up";
exports.btnTextSetPassword = "Set Password";
exports.btnTextWelcome = "Welcome";
exports.btnTextLabelText = "Please continue to set up your account."
exports.btnTextContinnue = "Continue";
exports.errorPasswordNotValid = "Password not valid.";
exports.labelTextClient = "Create a password for your account"

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET"; 
exports.logoTextHere = "Logo Here"; 
exports.confirmPasswordText = "Confirm Password";
exports.textOne = "Your password must contain";
exports.textTwo = "At least one capital letter (A-Z)";
exports.textTwoTwo = "At least one lowercase letter (a-z)";
exports.textThree = "At least one special character (e.g. .,!@#$%^&*)";
exports.textThreee = "At least one special character";
exports.textFour = "At least one number (0-9)";
exports.textFive = "Minimum length of 8 characters";
exports.textOne1 = "At least one capital letter and lowercase letter";
exports.textTwoTwo2 = "At least one lowercase letter";
exports.textThree3 = "At least one special character";
exports.textFour4 = "At least one number";
exports.textFive5 = "Minimum character length is 8 characters";
exports.agreeText = "I agree with";
exports.alreadyText = "Already have an account?";
exports.loginText = "Login"; 
exports.urlSignUp = "/account_block/accounts";
exports.setPasswordUrl = "/bx_block_forgot_password/passwords/set_new_password";
exports.emailValidText = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
exports.hasUpper = /[A-Z]/;
exports.hasLower = /[a-z]/;
exports.hasSpecialChar = /[@$!%*?&^()_\-+={}\[\]:";'<>,./\\#]/;
exports.hasNumber = /(?=.*\d)/;
exports.texmsAndConditionText = "Terms and Conditions"
exports.constructionManagementTool="Your new construction\nmanagement tool.";
exports.loremIpsum="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed\nnon faucibus nunc. Pellentesque."
// Customizable Area End

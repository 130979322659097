import React from "react";

import {
  Container,
  Button,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Paper,
  Grid,
  TextField,
  Modal,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { AddCircleOutline, Delete, Edit } from '@material-ui/icons';
import { calendar } from "./assets";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";



// Customizable Area End

import Proposalgeneration2Controller, {
  Props,
  configJSON,
  ProposalType
} from "./Proposalgeneration2Controller";


export default class Proposalgeneration2 extends Proposalgeneration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderIndividualProposalCard = (proposalItem: ProposalType) => {
    return (
      <Paper elevation={3} style={styles.individualContainer}>
        <div style={styles.header}>
          <Typography variant="h6" style={styles.titleText}>
            {proposalItem.attributes.title}
          </Typography>
          <Typography variant="body1" style={styles.priceText as React.CSSProperties}>
            {proposalItem.attributes.price}
          </Typography>
        </div>
        <Typography variant="body2" style={styles.creatorText}>
          By {proposalItem.attributes.name_of_proposal_creator} |{' '}
          {proposalItem.attributes.date_of_proposal}
        </Typography>
        <Typography variant="body2" style={styles.descText}>
          {proposalItem.attributes.description}
        </Typography>
        <div style={styles.flexCenter as React.CSSProperties}>
          <Button
            variant="outlined"
            color="primary"
            data-testid='edit-btn'
            startIcon={<Edit />}
            onClick={this.editProposals.bind(this, proposalItem)}
            style={styles.editButton}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            data-testid='view-btn'
            color="secondary"
            onClick={this.shareModalFun.bind(this, proposalItem)}

            style={styles.viewButton}
          >
            View
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Delete />}
            onClick={this.openDeletePopup.bind(this, proposalItem.id)}

            style={styles.deleteButton}
          >
            Delete
          </Button>
        </div>
      </Paper>
    );
  };

  // Customizable Area End

  render() {
    return (

      // Customizable Area Start
      <Container style={styles.scrollableContainer as React.CSSProperties}>
        <Typography variant="h4" style={styles.title}>
          Proposal Generation
        </Typography>
        <Button
          data-testid='add-btn'
          variant="outlined"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={this.toggleCreateForm}
        >
          Add
        </Button>
        <Grid container spacing={2}>
          {this.state.listOfProposals.map((proposalItem) => (
            <Grid item xs={12} key={proposalItem.id}>
              {this.renderIndividualProposalCard(proposalItem)}
            </Grid>
          ))}
        </Grid>
        <Modal
          open={this.state.deleteConfirmationPopup}
          onClose={this.closePopUp}
          style={styles.modal as React.CSSProperties}
        >
          <Container style={styles.modalContainer as React.CSSProperties}>
            <Typography variant="h5" style={styles.createTitle as React.CSSProperties}>
              Delete Confirmation
            </Typography>
            <Typography variant="body1" style={styles.deleteConfirmText as React.CSSProperties}>
              Are you sure you want to delete the proposal?
            </Typography>
            <div style={styles.flexCenter as React.CSSProperties}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.closePopUp}
                style={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.deleteHandler}
                style={styles.deleteButton}
              >
                Delete
              </Button>
            </div>
          </Container>
        </Modal>
        <Modal
          open={this.state.openCreateForm}
          onClose={this.closePopUp}
          style={styles.modal as React.CSSProperties}
        >
          <Container style={styles.modalContainer as React.CSSProperties}>
            <Typography variant="h5" style={styles.createTitle as React.CSSProperties}>
              Proposal Form
            </Typography>
            <TextField
              fullWidth
              data-testid='title-txt'
              variant="outlined"
              margin="normal"
              value={this.state.title}
              onChange={this.handleTitle}
              placeholder="Enter Title"
              InputProps={{
                style: styles.textInputdesStyles,
              }}
              InputLabelProps={{
                style: styles.labelStyles,
              }}
              multiline
            />
            <div style={styles.redAlert}>{this.showAlertMessage('title')}</div>

            <TextField
              fullWidth
              variant="outlined"
              data-testid='priceTest'
              margin="normal"
              value={this.state.price}
              onChange={this.handlePrice}

              placeholder="Enter Price"
              InputProps={{
                style: styles.textInputdesStyles,
              }}
              InputLabelProps={{
                style: styles.labelStyles,
              }}
              multiline
            />
            <div style={styles.redAlert}>{this.showAlertMessage('price')}</div>

            <TextField
              fullWidth
              variant="outlined"
              data-testid='creator-txt'

              value={this.state.creator}
              margin="normal"
              onChange={this.handleCreator}
              placeholder="Enter Creator Name"
              InputProps={{
                style: styles.textInputdesStyles,
              }}
              InputLabelProps={{
                style: styles.labelStyles,
              }}
              multiline
            />
            <div style={styles.redAlert}>{this.showAlertMessage('creator')}</div>

            <TextField
              data-testid='description-txt'

              fullWidth
              variant="outlined"
              value={this.state.description}
              margin="normal"
              onChange={this.handleDescription}
              placeholder="Enter Description"
              InputProps={{
                style: styles.textInputdesStyles,
              }}
              InputLabelProps={{
                style: styles.labelStyles,
              }}
              multiline

            />
            <div style={styles.redAlert}>{this.showAlertMessage('desc')}</div>
            <TextField
              fullWidth
              data-testid='customer-txt'
              variant="outlined"
              value={this.state.customerName}
              margin="normal"
              onChange={this.handleCustomerName}
              placeholder="Enter Customer"
              InputProps={{
                style: styles.textInputdesStyles,
              }}
              multiline
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CustomDatePicker
                data-testid='date-txt'
                placeholder="Enter date"
                format="dd/MM/yyyy"
                value={this.state.proposalDate}
                onChange={this.setProposalDate}


                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={calendar} alt="Calendar" />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            <div style={styles.redAlert}>{this.showAlertMessage('date')}</div>

            </MuiPickersUtilsProvider>
            <div style={styles.flexCenter as React.CSSProperties}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.closePopUp}
                style={styles.cancelButton}
              >
                Cancel
              </Button>
              <Button
                data-testid='save-btn'
                variant="outlined"
                color="primary"
                onClick={this.handleDataSaveClick}
                style={styles.saveButton}
              >
                Save
              </Button>
            </div>
          </Container>
        </Modal>
        <Modal
          open={!!this.state.viewproposal}
          style={styles.modal as React.CSSProperties}
        >
          <Container style={styles.modalContainer1 as React.CSSProperties}>
            <Typography variant="h5" style={styles.propTitle as React.CSSProperties}>
              View Proposal
            </Typography>
            <Typography variant="h6" style={styles.titleText}>
              Creator : {!!this.state.viewproposal && this.state.viewproposal.attributes.name_of_proposal_creator}
            </Typography>
            <Typography variant="h6" style={styles.titleText}>
              Price : {!!this.state.viewproposal && this.state.viewproposal.attributes.price}
            </Typography>
            <Typography variant="h6" style={styles.titleText}>
              Date Of proposal : {!!this.state.viewproposal && this.state.viewproposal.attributes.date_of_proposal}
            </Typography>
            <Typography variant="h6" style={styles.titleText}>
              Description : {!!this.state.viewproposal && this.state.viewproposal.attributes.description}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              data-testid='email-txt'
              margin="normal"
              value={this.state.customerEmail}
              onChange={this.handleEmail}
              placeholder="Enter Email"
              InputProps={{
                style: styles.textInputdesStyles,
              }}
              InputLabelProps={{
                style: styles.labelStyles,
              }}
              multiline
            />
            <div style={styles.flexCenter as React.CSSProperties}>
              <Button
                data-testid='shareModal-btn'
                variant="outlined"
                onClick={this.shareModalFun.bind(this, null)}

                style={styles.cancelButton1}
              >
                Cancel
              </Button>
              <Button
                data-testid='mail-btn'
                variant="outlined"
                onClick={this.shareProposalToMailWeb}
                style={styles.editButton}
              >
                Share
              </Button>
            </div>
          </Container>
        </Modal>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomDatePicker = styled(DatePicker)({
  borderRadius: '5px',
  background: '#FFFFFF',
  '& input[type="text"]': {
    borderBottom: 'none',
    padding: 28,
    color: '#000000',
    cursor: 'pointer',
  },
  '& input::placeholder': {
    color: '#594f4f !important',
  },
  '& .MuiInputAdornment-positionEnd': {
    marginRight: 10,
  },
  '& img': {
    cursor: 'pointer', 
  },
})
const styles = {
  individualContainer: {
    border: '1px solid #F1EBEB',
    marginBottom: '20px',
    borderRadius: '8px',
    padding: '12px',
  },
  scrollableContainer: {
    overflowY: 'auto',
  },
  labelStyles: {
    color: '#000000',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    fontSize: '16px',
    color: '#000000',
  },
  priceText: {
    fontSize: '16px',
    color: '#00A36C',
    textAlign: 'right',
  },
  creatorText: {
    fontSize: '12px',
    marginBottom: '5px',
    color: '#000000',
    width: '80%',
  },
  descText: {
    fontSize: '12px',
    marginBottom: '5px',
    color: 'grey',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  editButton: {
    padding: '4px 18px',
    border: '1px solid #00A36C',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#00A36C',
    cursor: "pointer"
  },
  viewButton: {
    padding: '4px 18px',
    border: '1px solid #15bdff',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#15bdff',
    cursor: "pointer"
  },
  deleteButton: {
    padding: '4px 18px',
    border: '1px solid #FF0000',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#FF0000',
  },
  title: {
    fontSize: '20px',
    marginTop: '26px',
    color: '#000000',
    marginBottom: '26px',
    cursor: "pointer"
  },
  createTitle: {
    fontSize: '30px',
    marginVertical: '10px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingTop: '60px',
  },
  propTitle: {
    fontSize: '30px',
    marginVertical: '10px',
    color: '#000000',
    textAlign: 'center',
    paddingTop: '10px',
  },
  textInputdesStyles: {
    backgroundColor: '#FFFFFF'
  },
  datePickerStyle: {
    height: "20px",
    background: "#FFFFFF"
  },
  modalContainer: {
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalContainer1: {
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    maxWidth: '600px',
    borderRadius: '10px',
    boxShadow: '0px 0px 2px 1px #5b5454',
  },
  cancelButton: {
    padding: '4px 18px',
    border: '1px solid #EE5353',
    borderRadius: '8px',
    margin: '7px 8px',
    backgroundColor: 'EE5353',
    color: '#000000',
  },
  saveButton: {
    padding: '4px 18px',
    border: '1px solid #00A36C',
    borderRadius: '8px',
    margin: '7px 8px',
    backgroundColor: '#00A36C',
    color: '#000000',
  },
  modal: {
    display: "flex",
    overflowY: 'auto',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: '#FFFFFF',
  },
  deleteConfirmText: {
    textAlign: "center",
    marginBottom: "20px",
  },
  cancelButton1: {
    padding: '4px 18px',
    border: '1px solid #FF0000',
    borderRadius: '8px',
    margin: '7px 8px',
    color: '#FF0000',
  },
  redAlert: {
    fontSize: 12,
    paddingVertical: 4,
    color: 'red'
  },
};
// Customizable Area End

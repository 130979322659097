import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Modal, Typography } from "@material-ui/core";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {crossIcon} from "./assets";
import StripeCheckout from 'react-stripe-checkout';
import Loader from "../../../../packages/components/src/Loader.web";
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON,
} from "./CustomisableusersubscriptionsController.web";

export default class SubscriptionDetails extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handlePlanButtons=(type:string,price:number,interval:string,planId:string,id:number)=>{
    if(this.state.currentPlan?.attributes?.name&&type.toLowerCase().includes(this.state.currentPlan?.attributes?.name)){
      return <Button style={{...styles.cancelBtn("#E0EDF0","#325962"),textTransform:"capitalize"}} disabled={true}>{configJSON.currentSubscription}</Button>
    }else{
       return <Button data-test-id={`change-sub-btn${id}`} style={{...styles.cancelBtn("#237182","white"),textTransform:"capitalize"}} 
           onClick={()=>this.handleOpenModal(type,price,interval,planId)}
           >{configJSON.chooseSubscription}</Button>
    }
  };
  handlePerMonth=(price:number,type:string)=>{
    switch(true){
     case type.toLowerCase().includes("half"):return (
      <Typography style={styles.textFontStyles("14px",300,"#475569")}>£{(price / 6).toFixed(2)} {configJSON.perMonth}</Typography>);
      case type.toLowerCase().includes("quarter"):return (
        <Typography style={styles.textFontStyles("14px",300,"#475569")}>£{(price / 3).toFixed(2)} {configJSON.perMonth}</Typography>);
      case type.toLowerCase().includes("year")||type.toLowerCase().includes("annual"):return (
        <Typography style={styles.textFontStyles("14px",300,"#475569")}>£{(price / 12).toFixed(2)} {configJSON.perMonth}</Typography>);
    }
  }
  handleFormatDate = () => {
    const date = new Date(localStorage.getItem("valid_up_to")||"");
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MyProfileHeader navigation={this.props.navigation} showSidebar={true}>
        {this.state.isLoading?<Loader loading/>:<></>}
      <Box style={styles.detailsMain}>
      <Box style={styles.cancelDetailsContainer }>
      <Box style={styles.titleTxt}>
       <Typography style={styles.textFontStyles("24px",700)}>
       {configJSON.subscriptionCanceled}
       </Typography>
       <Typography style={styles.textFontStyles("16px",600,"#1E293B")}>Status: <Box component={"span"} style={styles.toggleBtn("#DC2626","#FEE2E2")}>CANCELLED</Box></Typography>
      </Box>
      <Box>
        <Typography style={styles.textFontStyles("16px",400)}>
          Your subscription to the Specialist Cost Plan has been successfully canceled.
          <span style={styles.txtSpan}></span>
          - You will continue to have access to your current plan until the end of the current billing cycle.<br></br>
          - After the end of the billing cycle <span style={styles.textFontStyles("16px",600)}>{this.handleFormatDate()}</span> , you will lose access to the detailed cost plan from our professional Quantity Surveyor.
          <span style={styles.txtSpan}></span>
          If you have any questions or need further assistance, please contact our support team.
          </Typography>
      </Box>
      <Button data-test-id="returnBtn" style={{...styles.cancelBtn("#237182","white"),textTransform:"capitalize",width:"fit-content",border:"1px solid #C5E0E7"}} 
      onClick={()=>this.handleNavigation("CustomisableUserProfiles")}
      >
      {configJSON.returnToDashboard}
      </Button>
        </Box>
        <Box style={styles.cancelDetailsContainer }>
        <Typography style={styles.textFontStyles("20px" ,700,"#0F172A")}> {configJSON.subscriptions}:</Typography>
        <Grid container spacing={2}>
          {this.state.plans.length>0&&this.state.plans.map((item,index)=>(
          <Grid item xs={12} md={6} lg={4} key={item.id}>
          <Box style={{...styles.subCard}}>
        <Typography data-test-id={`planName-${index}`} style={styles.textFontStyles("20px",700,"#1E293B")}>{item.name}</Typography>
        <Box style={styles.centerSection}>
          <Box style={styles.amount}>
            <Typography style={styles.textFontStyles("30px",700,"#334155")}>£{item.price/100} </Typography>
            <Typography style={styles.textFontStyles("16px",300,"#475569")}> /{item.interval}</Typography>
          </Box>
          {this.handlePerMonth(item.price,item.name)}
        <Typography style={styles.textFontStyles("14px",300,"#475569")}>{configJSON.teamPlan}</Typography>
        </Box>
        {this.handlePlanButtons(item.name,item.price,item.interval,item.stripe_plan_id,item.id)}
        </Box>  
          </Grid>
          ))}
        </Grid>
      </Box>
      </Box>
      <Modal data-test-id="modal" open={this.state.modalConfirm.open} >
       <Box style={styles.modalContainer}>
       <Box style={{display:"flex"}}>
        <Typography style={{...styles.textFontStyles("24px",700,"#0F172A"),marginRight:"auto"}}>{configJSON.confirmSubscriptionChange}</Typography>
        <img data-test-id="closeModal" src={crossIcon} width={"20px"} height={"20px"} style={{cursor:"pointer"}} onClick={()=>this.handleCloseModal()}/>
       </Box>
       <Typography style={{...styles.textFontStyles("16px",300,"#1E293B"),marginTop:"16px"}}>
       By confirming, your current subscription will be changed to the {this.state.modalConfirm.plan}. The new plan will take effect immediately, and you will be billed  <Box component={"span"} style={styles.textFontStyles("16px",700,"#1E293B")}>£{this.state.modalConfirm.price/100}</Box> per {this.state.modalConfirm.interval}.
       </Typography>
       <Box style={{display:"flex",gap:"16px",marginTop:"40px"}}>
        <Button data-test-id="backModalBtn" style={{...styles.modalButtons("#E0EDF0","#325962"),marginLeft:"auto",textTransform:"capitalize"}} onClick={()=>this.handleCloseModal()}>{configJSON.back}</Button>
        <div>
        <StripeCheckout data-test-id='makePaymentHandler' stripeKey= "pk_test_51PGj5URqFKo1OdwPID3gcy7r8GwQDxkZGObn6IA94CuRIoiZUMoUDxRiBuMYQZlio9bhaVHioRTSPpekShPDOdFo00dBUuZLPy"
            token={(token)=>this.makePaymentHandler(token)}
          >
         <Button data-test-id="confirmPlan" style={{...styles.modalButtons("#237182","white"),textTransform:"capitalize"}} onClick={()=>this.closeChangeSubscription()}>{configJSON.confirm}</Button>
        </StripeCheckout>
        </div>
       </Box>
       </Box>
      </Modal>
      </MyProfileHeader>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles={
  detailsMain:{
    padding: "24px 48px 24px 48px",
    fontFamily: "Poppins",
    marginLeft:"6rem",
    display:"flex",
    flexDirection:"column",
    gap:"2rem",
    backgroundColor:"#f0f5f7",
    height:"calc(100vh - 79px)",
    overflowY:"auto",
    "-webkit-scrollbar": { 
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  } as const,
  cancelDetailsContainer:{
    display:"flex",
    flexDirection:"column",
    gap:"24px",
    backgroundColor:"#FFFFFF",
    padding:"24px",
    borderRadius:"16px",
  }as const,
  textFontStyles:(size:string,weight:number,color:string="black")=>{
    return {
     fontFamily:"Poppins",
     fontWeight:weight,
     fontSize:size,
     color:color
    }
  },
  toggleBtn:(color:string,bgcolor:string)=>{
    return{
      backgroundColor:bgcolor,
      borderRadius:"40px",
      padding:"2px 8px",
      ...styles.textFontStyles("12px",700,color)
    }
  },
  subCard : {
    width: "100%",
    height: "282px",
    boxSizing:"border-box",
    padding: "24px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    gap: "24px",
    borderRadius: "12px",
    border: "1px solid #CBD5E1",
  } as const,
  centerSection:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    gap:"8px"
  } as const,
  cancelBtn:(color:string,colorF:string)=>{return{
    backgroundColor:color,
    color:colorF,
    padding:"10px 15px",
    borderRadius:"9px",
    fontSize:"16px",
    fontFamily:"Poppins",
    fontWeight:600,
    width:"100%",
    cursor:"pointer",
    marginTop:"auto",
  }},
  titleTxt:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  txtSpan:{ 
    display: "block",
    marginBottom: "8px"
   },
   amount:{
    display:"flex",
    alignItems:"center"
  },
  modalButtons:(color:string,colorF:string)=>{return{
    backgroundColor:color,
    color:colorF,
    padding:"16px",
    borderRadius:"9px",
    fontSize:"16px",
    fontFamily:"Poppins",
    fontWeight:600,
    cursor:"pointer"
  }},
  modalContainer:{
    position: 'absolute',
    width:"580px",
    backgroundColor: "#fff",
    padding: "40px 32",
    borderRadius:"16px",
    top:"50%",
    left:"50%",
    transform:"translate(-50%,-50%)"
  } as const,
}
// Customizable Area End
import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, CartesianGrid, YAxis, XAxis, Bar, Tooltip, TooltipProps } from "recharts";
import { Button, Grid,Box, Typography } from "@mui/material";
import { document,expandDownArrow } from "./assets";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";

// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  customTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <Box
          style={styles.tooltipCont}
        >
          <Box
            style={styles.tooltipTitleCont}
          >
            <Typography
              style={styles.tooltipTitleText}
            >{label}</Typography>
          </Box>
          <Box
            style={styles.tooltipTaskCont}
          >
            {payload.map((pld: any) => (
              <Box
                style={styles.tooltipTaskTitleCont}
              >
                <Typography sx={styles.tooltipTaskTitle}>{configJSON.tasksText} </Typography>
                <Box style={styles.tooltipTaskPercent}>{pld.value}%</Box>
              </Box>
            ))}
          </Box>
        </Box>
      );
    }
  
    return null;
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MyProfileHeader showSidebar={true} >
        <Grid container sx={styles.gridCont} gap={2}>
          <Grid item lg={3.6} md={5.4} xs={10.2} sx={styles.gridItem} >
          <Box sx={styles.flexBox}>
            <Typography style={styles.titleText}>{configJSON.roomCostBreakdown}</Typography>
            <img src={document} alt="document" style={styles.iconImage} />
          </Box>
            <Box sx={styles.pieRoomCont}>
            <Box sx={{position: 'relative'}}>
            <Box
              sx={styles.totalCostCont}
            >
              <Typography style={styles.costText}>{configJSON.totalCost}</Typography>
              <Typography component="span" style={styles.costAmountText}>
                {configJSON.currency}967,675.67
              </Typography>
            </Box>
              <ResponsiveContainer height={190} width={160}>
                <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }} style={styles.pieChartContainer}>
                  <Pie
                    data={configJSON.data}
                    cx={80}
                    cy={100}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={1}
                    cornerRadius={4}
                    dataKey="value"
                  >
                    {configJSON.data.map((_: string, index: number) => (
                      <Cell key={`cell-${index}`} fill={configJSON.COLORS[index % configJSON.COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Box>
              <Box sx={styles.dataBox}>
                {configJSON.data.slice(0,5).map((item: {name: string, value: number}, index: number) => (
                  <Box key={item.name} sx={styles.dataItemBox}>
                    <Box style={styles.roomColorCont}>
                     <Box sx={{...styles.colorCont,  bgcolor: configJSON.COLORS[index],}}></Box>
                     <Typography style={styles.itemNameText}>{item.name}</Typography>
                    </Box>
                    <Typography style={styles.itemValueText}>
                      {configJSON.currency}
                      {item.value}
                    </Typography>
                  </Box>
                ))}
                <Button sx={styles.viewMoreBtn}
                disableRipple
                onClick={this.onClickViewMoreRooms}
                data-test-id='viewMoreBtn'
                 endIcon={<img src={expandDownArrow} alt='expand arrow' width={7.45} height={4.39}/>}>
                  {configJSON.viewMoreBtn}

                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3.6} md={5.4} xs={10.2} sx={styles.gridItem}>
            <Box sx={styles.flexBox}>
              <Typography style={styles.titleText}>{configJSON.tasklistSummary}</Typography>
              <img src={document} alt="document" style={styles.iconImage} />
            </Box>
          <ResponsiveContainer width={'100%'} height={210}>
           <BarChart
           width={382}
             data={configJSON.barData}
              margin={{
                top: 10,
                right: 0,
                left: -20,
                bottom: 0,
              }}
             barCategoryGap={0}
             >
             <CartesianGrid strokeDasharray="3 3" vertical={false} />
             <YAxis
              padding={{ bottom: 0 }}
              unit="%"
            domain={[0, 100]}
            tickCount={5}
            tickLine={false}
            axisLine={false}
            style={{...styles.barTextStyles, marginLeft: "33px",}}
              tick={{ fill: "#64748B", fontFamily: "Poppins" }}
              />
              <Tooltip 
              data-test-id='toolTip'
               content={this.customTooltip}
                cursor={{
                  fill: "#F1F5F9",
                  width: 46,
              }}/>
             <XAxis
              padding={{ left: 0 }}
              stroke="#CBD5E1"
              tick={{ fill: "#64748B", dx: -15 }}
              dataKey="name"
              tickLine={false}
              style={styles.barTextStyles}
              />
              <Bar
            dataKey="uv"
            minPointSize={5}
          >
            {configJSON.barData.map((_: string, index: number) => (
              <Cell
                width={46}
                radius={4}
                cursor="pointer"
                fill={configJSON.BARCOLORS[index]}
                key={`cell-${index}`}
              />
            ))}
              </Bar>
             </BarChart>
          </ResponsiveContainer>
          </Grid>
          <Grid item lg={3.6} md={5.2} xs={10.2} sx={styles.gridItem} gap={5}>
          <Box sx={styles.flexBox}>
            <Typography style={styles.titleText}>{configJSON.invoiceTitle}</Typography>
            <img src={document} alt="document" style={styles.iconImage} />
          </Box>
          <Box sx={styles.invoiceRelativeBox}>
            <Box sx={styles.pieRoomCont}>
              <Box sx={{position: 'relative'}}>
                <ResponsiveContainer height={190} width={160}>
                  <PieChart
                    margin={{ top: 0, left: -20, right: 0, bottom: 0 }}
                    height={100}
                    style={styles.pieChartContainer}
                  >
                    <Pie
                      data={configJSON.invoiceData}
                      cx={100}
                      cy={100}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                      paddingAngle={1}
                      cornerRadius={2}
                      startAngle={-40}
                      endAngle={405}
                    >
                      {configJSON.data.map((_: {name: string, value: number}, index: number) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={configJSON.invoiceColor[index % configJSON.invoiceColor.length]}
                        />
                      ))}
                    </Pie>
                   
                  </PieChart>
                </ResponsiveContainer>
                    <Box
                  sx={styles.totalInvoiceCont}
                >
                  <Typography style={styles.costText}>{configJSON.totalInvoices}</Typography>
                  <Typography component="span" style={styles.costAmountText}>
                    30
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.dataBox}>
                {configJSON.invoiceData.slice(0,5).map((item: {name: string, value: number}, index: number) => (
                  <Box key={item.name} sx={styles.dataItemBox}>
                    <Box style={styles.roomColorCont}>
                     <Box sx={{...styles.colorCont,  bgcolor: configJSON.COLORS[index],}}></Box>
                     <Typography style={styles.itemNameText}>{item.name}</Typography>
                    </Box>
                    <Typography style={styles.itemValueText}>
                      {item.value}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          </Grid>
        </Grid>
      </MyProfileHeader>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const styles = {
  totalInvoiceCont: {
    position: 'absolute',
    left: '30px',
    top: '85px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
  },
  tooltipCont: {
    backgroundColor: "white",
    width: 135,
    height: 72,
    borderRadius: 8,
    boxShadow: "2px 3px 9px -7px rgba(0,0,0,0.75)",
    overflow: "hidden",
  },
  tooltipTitleCont: {
    backgroundColor: "#F1F5F9",
    height: 32,
    display: "flex",
    alignItems: "center",
    paddingLeft: 14,
    paddingRight: 14,
  },
  tooltipTitleText: {
    color: "#0F172A",
    fontSize: 12,
    fontWeight: 400,
    fontFamily: 'Poppins',
    lineHeight: '16px'
  },
  tooltipTaskCont:{
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 14,
    paddingRight: 14,
  },
  tooltipTaskTitleCont:{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  tooltipTaskTitle: {color: '#0F172A', fontSize: 12, fontWeight: 400, fontFamily: 'Poppins', lineHeight: 16},
  tooltipTaskPercent: { color: '#0F172A', fontWeight: 600, fontFamily: 'Poppins', fontSize: 12,lineHeight: 16 },
  barTextStyles:{
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  gridCont: {marginLeft: {sm:'5.5rem', xs: '6rem'}, padding: '30px 50px 10px 50px' },
  pieRoomCont:{ display: 'flex', justifyContent: 'space-between', width: '100%',
    position: 'relative',
    flexDirection: {sm: 'row', xs: 'column'}, alignItems: 'center', gap: '5px'
   },
  colorCont: {borderRadius: 50, width: 12, height: 12},
  roomColorCont: {display: 'flex', gap: 5, alignItems: 'center'},
  viewMoreBtn: {width: '87px',padding: 0, whiteSpace: 'nowrap', textTransform: 'none',backgroundColor: 'none',fontSize: '12px', color: '#237182', fontWeight: 600, fontFamily: 'Poppins','&: hover': {backgroundColor: 'transparent'}},
  totalCostCont: {
    position: 'absolute',
    left: '30px',
    top: '80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px'
  },
  gridItem: {
    padding: '15px',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxSizing: 'border-box',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  titleText: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#64748B',
  },
  iconImage: {
    width: '15px',
    height: '15px',
  },
  relativeBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 1
  },
  invoiceRelativeBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  costText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#1E293B',
  },
  costAmountText: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    color: '#237182',
  },
  pieChartContainer: {
    width: '399px',
    height: '300px',
  },
  dataBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '50%',
  },
  dataItemBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  itemNameText: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#1E293B',
    whiteSpace: 'nowrap'
  },
  itemValueText: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#334155',
  },
};
// Customizable Area End

import React from 'react'
const dropDown = require("../../blocks/customisableuserprofiles/assets/dropDown.png")

function CustomDropdown() {
  return (
    <img
      src={dropDown} 
      alt="Dropdown"
      style={{ width: '16px', height: '16px' ,
      position: 'absolute',
      right: '13px', 
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      color:"#64748B"
      }} 
      
    />
  )
}

export default CustomDropdown

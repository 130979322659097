import React from "react";

// Customizable Area Start
import { Box, Button, Grid, IconButton, TextField, Typography, styled } from "@material-ui/core";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import { slider2, view, roght } from "./assets";
import { configJSON } from "./EmailAccountRegistrationController";
import Dialog from '@material-ui/core/Dialog';
const loginbg = require("../assets/loginbg.jpg");
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import { VisibilityOffOutlined as VisibilityOffOutlinedIcon, VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons';

// Customizable Area End

// Customizable Area Start 
const CustomCheckbox = styled('input')({
  color: "#325962",
  borderRadius: "7px",
  cursor: 'pointer',
  marginRight: '10px',
  marginLeft: '-1px',
  position: 'relative',
  '-moz-appearance': 'none',
  '-webkit-appearance': 'none',
  height: '20px',
  width: '20px',
  outline: 'none',
  border: '1px solid #6c6969',
  '&:checked::after': {
    content: '""',
    top: '1px',
    position: 'absolute',
    left: '5px',
    height: '10px',
    width: '6px',
    transform: 'rotate(45deg)',
    border: 'solid white',
    borderWidth: '0 2px 2px 0',

  },
  '&:checked': {
    borderColor: '#237182',
    backgroundColor: '#237182',
  },
});
const ImageWrapper = styled(Box)({
  '& .imageViewBox': {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
    backgroundImage: `url(${loginbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    '@media (max-width:360px)': {
      alignItems: "center",
      justifyContent: 'center',
      boxSizing: "border-box",
      textAlign: "center",
      backgroundImage: `url(${loginbg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "90vh",
    },

  },
  "& .boxNextView": {
    display: "flex",
    flexDirection:'column',
    justifyContent: "center",
    alignItems: "center",
    height: "85vh",
    width: "93.057%"
  },
  "& .boxTextStyle": {
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    width:'83.584%',
    marginInline: 'auto',
    minWidth: "560px"
  },
  "& .backgroundColorImage": {
    backgroundColor: '#325962',

  },
  " & .yourEmailContaian": {
    marginTop: '10px'
  },

  " & .inputStylesPass": {
    backgroundColor: "#F6F8F9",
    borderRadius: "8px",
    fontWeight: 400,
    fontSize: "16px",
    border: "1px solid #CBD5E1",
    background: "#FCFDFE",
    paddingTop: "1px",
    paddingBottom: '1px',
    top: '1px'
  },
  "& .yourPasswordContaian ": {
    marginTop: '8px'
  },
  "& .errorHanlerMsg": {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "left",
    userSelect: "none",
    color: "red"
  },
  " & .singupButton": {
    marginTop: '32px'
  },
  "& .navigationToLogin":{
    marginBlock:'30px',
    paddingBottom: '20px'
  },
  "& .imgPic": {
    height:'65.5%',
    marginInline:'auto',
    objectFit:'contain'
  },
  "& .logohereText": {
    width:'100%',
    fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "white",
    '@media (max-width:360px)': {
      paddingLeft: '200px'
    },
    '@media (max-width:412px)': {
      paddingLeft: '80px'
    },

  },
  "& .textStyle": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "left",
    color: "#334155",
    marginTop: '12px',
  },
  "& .errormessage": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginLeft: "6px",
    color: "#334155",
  },
  "& .msgText": {
    color: "white",
    lineHeight: "1.2",
    fontFamily: "Poppins",
    fontSize: "38px",
    fontWeight: 700,
    marginTop: '8px',
    width:'80%',
    '@media (max-width:412px)': {
      marginLeft: '36px',
      marginRight: '36px',
      textAlign: "center",
      color: "white",
      lineHeight: "1.2",
      fontFamily: "Poppins",
      fontSize: "30px",
      fontWeight: 700,
      marginTop: '8px',
    },
    '@media (max-width:360px)': {
      marginLeft: '36px',
      marginRight: '36px',
      textAlign: "center",
      color: "white",
      lineHeight: "1.2",
      fontFamily: "Poppins",
      fontSize: "30px",
      fontWeight: 700,
      marginTop: '8px',
    },
    '@media (max-height:463px)': {
      fontSize: "1.5rem",
      marginTop: 0,
    },
    '@media (max-height:363px)': {
      fontSize: "1rem",
      marginTop: 0,
    },
  },
  "& .smallText": {
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 100,
    fontSize: "18px",
    color: "white",
    marginTop: "13px",
    '@media (max-width:412px)': {
      paddingLeft: '22%',
      paddingRight: '22%',
      textAlign: "center",
    },
    '@media (max-width:360px)': {
      paddingLeft: '22%',
      paddingRight: '22%',
      textAlign: "center",
    },
    '@media (max-height:463px)': {
      fontSize: "1rem",
      marginTop: "0.5rem",
    },
    '@media (max-height:363px)': {
      fontSize: "0.75rem",
    },
  }
});
const FormWrapper = styled(Box)({
  width:'85.28%',
  height:'78.668vh',
  '@media (min-width:1900px)': {
    height:'auto'
  },
})

const webStyles = {
  errStyle: {
    color: "red",
    fontSize: "14px",
    // margin:"5px 3px",

    fontFamily: "'Poppins', sans-serif",
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
  },

  textColor: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Poppins",
    lineHeight: "24px",
    textAlign: "left"
  } as const,
  mainbox: {
    display: 'flex',
    height: '100vh',
    padding: 0,
    alignItems: "stretch",
    justifyContent: "stretch",
    backgroundColor: "rgba(50, 89, 98, 1)",
    overflowY: "hidden"
  } as const,
  rightBox: {
    backgroundColor: "white",
    borderTopLeftRadius: "24px",
    borderBottomLeftRadius: "24px",
    minHeight: "100vh",
    maxHeight: "100vh",
    overflowY: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    marginBottom: "0",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  display: "flex",
  width: "100%",
  height: "100vh"
} as const,

  leftbox: {
    padding: "4px",
    height: "100vh",
    width: "50%",
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
    backgroundImage: `url(${loginbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  } as const,
  signupText: {
    ontWeight: "bold",
    textAlign: "center",
    color: "#0F172A",
    fontSize: "30px",
  },

  dontText: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#334155',
  } as const,
  linktext: {
    color: "#237182",
    fontSize: "18px"
  },



  signuptext: {
    marginTop: '-30px',
    fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "center",
  } as const,
  validationText: {
    marginTop: '6px',
    alignItems: "center",
    fontFamily: 'Poppins',
  } as const,
  privacytext: {
    color: '#237182',
    fontSize: '14px',
    cursor: 'pointer',
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "600",
    lineHeight: "22px",
    textAlign: "left",
    marginRight: "3px",
    userSelect: "none",
  } as const,
  privacytext1: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "left",
    marginRight: "3px",
    userSelect: "none",
    cursor: 'pointer',
    color: '#0F172A',

  } as const,
  privacytext2: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "left",
    marginRight: "3px",
    userSelect: "none",
    cursor: 'pointer',
    color: '#0F172A',
  } as const,
  signupTextStyle: {

    width: "100%",
    padding: "16px",
    border: "none",
    cursor: "pointer",
    color: "#64748B",
    background: "#F1F5F9",
    borderRadius: "8px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    fontFamily: "Poppins",
    textTransform: 'none',
  } as const,
  signupTextStylewith: {
    width: "100%",
    padding: "16px",
    border: "none",
    cursor: "pointer",
    color: "#FFFFFF",
    background: "#237182",
    borderRadius: "8px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    fontFamily: "Poppins",
    textTransform: 'none',
  } as const,
  textspan: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '700',
    fontSize: '18px',
    color: '#237182',
    cursor: 'pointer',
    userSelect: 'none'
  } as const,
  checkBoxText: {
    display: "flex",
    alignItems: "center",
  },
  checkBoxText1: {
    marginRight: '-4px',
    paddingTop: '24px'
  },

  agreeTextSty: {
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "'Poppins', sans-serif",
  },
  privacyPolicyText: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: "Poppins",
    color: '#1E293B',
  } as const,
  Acceptance: {
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "left"
  } as const,
  iconButtonStyle: {
    position: 'absolute',
    right: ' 10px',
    top: '10px',
  } as const,
  closeIconSty: {
    color: "#0F172A",
    height: "24px",
    width: "24px"
  } as const
};
const modalBox = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        width: "900px",
        height: "489px",
        borderRadius: "16px"
      },
      paperWidthSm: {
        maxWidth: "900px",
        height: "489px"
      },
    },
  },
});

const inputStyles = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  backgroundColor: "#F6F8F9",
  borderRadius: "8px",
  "&:focus": {
    border: "1px solid #CBD5E1",
  },
  background: "#FCFDFE",
  paddingTop: '8px',
  paddingBottom: '8px',
  marginTop: '6px'

} as const
const inputStylesPass = {
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
  backgroundColor: "#F6F8F9",
  borderRadius: "8px",
  "&:focus": {
    border: "1px solid #CBD5E1",
  },
  background: "#FCFDFE",
  marginTop: '6px',
  paddingTop: '3px',
  paddingBottom: '3px',
} as const
const errorMessageStyles = {
  color: 'red',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16px',
  marginLeft: '0rem',
};
const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
      },
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const StyleSheetData = {
  EyePasswardIcon: {
    cursor: 'pointer',
    color: '#94A3B8',
    position: "absolute",
    right: "18px",
    top: "20px"
  },
  
  InputTextFieldValue: {
    fontFamily: "Poppins",
    outline: "none",
    marginTop: "4px",
    display: "block",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "10px 8px 10px 8px",
    height: "56px",
  },
  errorFieldShow:{ 
    color: "red",
    marginLeft: "0rem",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    fontFamily: "Poppins",
  },
}

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <ImageWrapper>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                className="imageViewBox"
              >
                <Box className="boxNextView">
                  <Typography className="logohereText">
                    {configJSON.logoTextHere}
                  </Typography>
                  <img src={slider2} className="imgPic" />
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className="boxTextStyle">
                        <Typography className="msgText">
                          Your new construction management tool.
                        </Typography>
                        <Typography className="smallText">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className="backgroundColorImage" >
              <Box data-test-id='oKay' style={webStyles.rightBox} component="form" onSubmit={this.handleSubmit}>
                <FormWrapper>
                  <Typography component="h2" style={webStyles.signuptext}>
                    {configJSON.btnTextSignUp}
                  </Typography>

                  <Box className="yourEmailContaian">
                    <Typography className="textStyle">{configJSON.labelEmail}</Typography>
                      <Box style={{position:"relative"}}>
                        <input type="text" name="email" 
                          style={{...StyleSheetData.InputTextFieldValue, backgroundColor:this.state.email?"rgba(255, 255, 255, 1)":"rgba(252, 253, 254, 1)", border:!!this.state.errors.email ? "1px solid #F87171":"1px solid #CBD5E1",fontSize: "14px",
                            fontWeight: 400}}
                          value={this.state.email}
                          data-test-id="txtInputEmail"
                          onBlur={this.handleBlur}
                          onChange={this.handleInputChange}
                          placeholder="Enter your email"
                        />
                         <Typography component={"span"} className="errormessage" style={StyleSheetData.errorFieldShow}>{ this.state.errors.email}</Typography>
                      </Box>
                    
                  </Box>
                  <Box><Typography className="textStyle">{configJSON.labelPassword}
                  </Typography>
                  <Box style={{position:"relative"}}>
                        <input type="text" 
                          name="passwordtext" 
                          style={{...StyleSheetData.InputTextFieldValue, backgroundColor:this.state.originalPassword?"rgba(255, 255, 255, 1)":"rgba(252, 253, 254, 1)", border:!!this.state.errors.password ? "1px solid #F87171":"1px solid #CBD5E1",fontSize: "14px",
                          fontWeight: 400}}
                          value={this.state.showPassword ? this.state.asterisk : this.state.originalPassword}
                          data-test-id="txtInputPassword"
                          onBlur={(e:any)=>this.handleBlur(e)}
                          onChange={(e:any) => this.handleChangePassword(e)}
                          placeholder="Enter your password"
                        />
                         {this.state.showPassword ? (
                          <VisibilityOffOutlinedIcon
                            data-test-id="IconShowCurrentPassword"
                            style={StyleSheetData.EyePasswardIcon as any}
                            onClick={this.toggleVisibility}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            data-test-id="IconHideCurrentPassword"
                            style={StyleSheetData.EyePasswardIcon as any}
                            onClick={this.toggleVisibility}
                          />
                        )}
                         <Typography component={"span"} className="errormessage" style={StyleSheetData.errorFieldShow}>{ this.state.errors.password}</Typography>
                      </Box>
                  </Box>
                  <Box>
                    <Typography className="textStyle">{configJSON.confirmPasswordText}</Typography>
                    <Box style={{position:"relative"}}>
                        <input type="text" name="originalPassword1" 
                          style={{...StyleSheetData.InputTextFieldValue, backgroundColor:this.state.originalPassword1?"rgba(255, 255, 255, 1)":"rgba(252, 253, 254, 1)", border:!!this.state.errors.confirmPassword ? "1px solid #F87171":"1px solid #CBD5E1",fontSize: "14px",
                            fontWeight: 400}}
                          value={this.state.showPassword1 ? this.state.asterisk1 : this.state.originalPassword1}
                          data-test-id="txtInputPassword1"
                          onBlur={(e:any)=>this.handleBlur(e)}
                          onChange={(e:any) => this.handleChangePassword1(e)}
                          placeholder="Confirm your password"
                        />
                         {this.state.showPassword1 ? (
                          <VisibilityOffOutlinedIcon
                            data-test-id="IconShowCurrentPassword"
                            style={StyleSheetData.EyePasswardIcon as any}
                            onClick={this.toggleVisibility1}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            data-test-id="IconHideCurrentPassword"
                            style={StyleSheetData.EyePasswardIcon as any}
                            onClick={this.toggleVisibility1}
                          />
                        )}
                         <Typography component={"span"} className="errormessage" style={StyleSheetData.errorFieldShow}>{ this.state.errors.confirmPassword}</Typography>
                      </Box>
                  </Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className="yourPasswordContaian">
                        <Box>
                          <Typography component={"span"} className="textStyle">{configJSON.textOne}</Typography>
                        </Box>
                        {this.state.validationRules.map((rule:any, index:any) => (
                          <Box style={webStyles.validationText} key={index}>
                            <img src={rule.condition ? roght : view} />
                            <Typography component={"span"} className="errormessage">{rule.text}</Typography>
                           </Box>
                         ))} 
                      </Box>
                    </Grid>
                  </Grid>
                  <Box mt={1} style={webStyles.checkBoxText1}>
                    <Box style={webStyles.checkBoxText}>
                      <CustomCheckbox
                        type="checkbox"
                        data-test-id="checkone"
                        style={{ color: "#237182" }}
                        name="agreeToTerms"
                        checked={this.state.agreeToTerms}
                        onChange={this.handleInputChange} />

                      <Box className="disclaimerandnotes" style={webStyles.agreeTextSty}>


                        <Typography component={"span"} data-test-id='iagree' style={webStyles.privacytext1}>
                          {configJSON.agreeText}
                        </Typography>

                        <Typography component={"span"} data-test-id='privacyModal' onClick={this.handleOpenModal} style={webStyles.privacytext}>
                          Privacy Policy
                        </Typography>
                        <Typography component={"span"} data-test-id='and' style={webStyles.privacytext2}>
                          and
                        </Typography>

                        <Typography data-test-id='termsModal' onClick={this.handleOpenTermsCondModal}
                          component={"span"} style={webStyles.privacytext}>{
                            configJSON.texmsAndConditionText}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      {this.state.errors.terms && (<Typography color="error" className="errorHanlerMsg" >{this.state.errors.terms}</Typography>)}
                    </Box>
                  </Box>
                  <Box className="singupButton">
                    <Button
                      data-test-id="submitBtn"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!this.validateConditions()}
                      fullWidth
                      style={this.validateConditions() ? webStyles.signupTextStylewith : webStyles.signupTextStyle}
                    >
                      {configJSON.btnTextSignUp}
                    </Button>
                  </Box>
                  <Box className="navigationToLogin">
                    <Typography style={webStyles.dontText}>{configJSON.alreadyText} {" "}
                      <Typography component={"span"} data-test-id="login-navigation" onClick={this.handleLoginNavigate} style={webStyles.textspan}>{configJSON.loginText}</Typography>
                    </Typography>
                  </Box>
                </FormWrapper>
              </Box>
            </Grid>

          </Grid>
          <ThemeProvider theme={modalBox}>
            <Dialog
              style={{ borderRadius: "16px" }}
              open={this.state.openModal}
              onClose={this.handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box pt={1} px={1}>
                <DialogTitle id="alert-dialog-title">
                  <Typography style={webStyles.privacyPolicyText}>Privacy Policy</Typography>
                  <IconButton
                    data-test-id="crossCatch"
                    style={webStyles.iconButtonStyle}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={this.handleCloseModal}
                  >
                    <CloseIcon style={webStyles.closeIconSty} />
                  </IconButton>
                </DialogTitle>
              </Box>
              <DialogContent style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <Box px={1} pb={1} pt={3}>
                  <DialogContentText id="alert-dialog-description">
                    <Typography gutterBottom style={webStyles.textColor}>
                      We are committed to protecting the privacy of our users. This Privacy Policy outlines the types of personal information that is received and collected by us and how it is used.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      Information Collection and Use: We may collect personal information when you register for an account, use our services, or interact with our website or application. This information may include your name, email address, postal address, phone number, and other details you provide.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <span style={webStyles.Acceptance}>Log Data: </span>
                      Like many other websites/applications, we collects information that your browser sends whenever you visit our site. This may include information such as your IP address, browser type, browser version, pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <Typography component={"span"} style={webStyles.Acceptance}>Security: </Typography>
                      We takes reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <span style={webStyles.Acceptance}>Log Data: </span>
                      Like many other websites/applications, we collects information that your browser sends whenever you visit our site. This may include information such as your IP address, browser type, browser version, pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <Typography component={"span"} style={webStyles.Acceptance}>Security: </Typography>
                      We takes reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                    </Typography>
                  </DialogContentText>
                </Box>
              </DialogContent>

            </Dialog>
          </ThemeProvider>

          <ThemeProvider theme={modalBox}>
            <Dialog
              open={this.state.openTermsCondModal}
              onClose={this.handleCloseTermsCondsModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box pt={1} px={1}>
                <DialogTitle id="alert-dialog-title">
                  <Typography style={webStyles.privacyPolicyText}>Terms and Conditions</Typography>
                  <IconButton
                    data-test-id="crossCatchs"
                    style={webStyles.iconButtonStyle}
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={this.handleCloseTermsCondsModal}
                  >
                    <CloseIcon style={webStyles.closeIconSty} />
                  </IconButton>
                </DialogTitle>
              </Box>
              <DialogContent style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)', maxHeight: '400px', overflowY: 'auto' }}>
                <Box px={1} pb={1} pt={3}>
                  <DialogContentText id="alert-dialog-description">
                    <Typography gutterBottom style={webStyles.textColor}>
                      Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      It's also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact there is no difference.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <span style={webStyles.Acceptance}>Acceptance of Terms: </span>
                      By accessing or using this website/application/service, you agree to be bound by these terms and conditions.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <Typography component={"span"} style={webStyles.Acceptance}>Use License: </Typography>
                      Permission is granted to temporarily download one copy of the materials (information or software) on this website/application for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not modify or copy the
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <span style={webStyles.Acceptance}>Acceptance of Terms: </span>
                      By accessing or using this website/application/service, you agree to be bound by these terms and conditions.
                    </Typography>
                    <Typography gutterBottom style={webStyles.textColor}>
                      <Typography component={"span"} style={webStyles.Acceptance}>Use License: </Typography>
                      Permission is granted to temporarily download one copy of the materials (information or software) on this website/application for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not modify or copy the
                    </Typography>
                  </DialogContentText>
                </Box>
              </DialogContent>

            </Dialog>
          </ThemeProvider>
        </ImageWrapper>

      </ThemeProvider>


      // Customizable Area End
    );
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.roomCostBreakdown = "Room Cost Breakdown ";
exports.totalInvoices= "Total Invoices:";
exports.invoiceTitle = 'Invoice Summary';
exports.currency = '£';
exports.totalCost= "Total Cost:";
exports.viewMoreBtn = 'View more';
exports.tasklistSummary = 'Tasklist Summary';
exports.tasksText = 'Tasks';
exports.data = [
  { name: "Room Name", value: 400 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 200 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
  { name: "Room Name", value: 300 },
];
exports.COLORS = ["#60A5FA", "#F87171", "#FCD34D", "#FF8042", '#F472B6', '#ABCDD5', '#818CF8', '#34D399'];
exports.invoiceData = [
  { name: "Paid:", value: 400 },
  { name: "Unpaid:", value: 300 },
  { name: "Upcoming:", value: 300 },
  { name: "OverDue:", value: 100 },
];
exports.invoiceColor = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
exports.BARCOLORS = ["#3B82F6", "#F59E0B", "#10B981", "#EF4444"];
exports.barData = [
  {
    name: "To Do",
    uv: 40,
    amt: 2400,
  },
  {
    name: "Progress",
    uv: 74,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Completed",
    uv: 20,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Blocked",
    uv: 27,
    pv: 3908,
    amt: 2000,
  },
];

// Customizable Area End
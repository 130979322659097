import React, { Component } from 'react';
const logoImg = require("./logo.svg");
const profileImg = require("./img.svg");
const logout = require("./exit.svg");
const alarm = require("./alarm.png");
const setting = require("./setting.png");

const styleSheet = {
    navBar: {
        display: "flex",
        justifyContent: "space-between",
        height: "67px",
        alignItems: "center",
        padding: "0px 40px 0px 14px",
        borderBottom: "1px solid #E2E8F0"
    },
    logoNav: {
        display: "flex",
        alignContent: "center"
    },
    menubar: {
        display: "flex",
        listStyle: "none",
        gap:"26px",
        padding:"0px",
        margin:"0px 0px 0px 40px",
        alignItems: "center"
    },
    rightSideLogo: {
        width: "10px",
        height: "10px",
        border: "1px solid black"
    },
    rightLogo: {
        display: "flex",
        gap: "8px",
        alignItems: "center"
    },
    main: {
        display: "flex",
        height: "100%"
    },
    sidebar: {
        display: "flex",
        width: "72px",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRight: "1px solid #E2E8F0"
    },
    sidebarMenu: {
        width: "24px",
        height: "24px",
        padding: "12px 8px 12px 8px",
    },
    list: {
        color: "#334155",
        fontWeight: 600,
        fontSize: "14px",
        fontFamily: "Poppins",
        cursor: "pointer",
        padding: "8px 6px 8px 6px",
        borderRadius: "8px",
    }
}

interface RouteParams {
    id: string;
}
interface MyProps {

};
interface MyState {
    tabHover: string;
};

class Header extends Component<MyProps, MyState> {
    constructor(props: MyProps) {
        super(props);
        this.state = {
            tabHover: ""
        };
    }

    handleChangeHoverEffect(str: string) {
        this.setState({ tabHover: str });
    }

    handleNavigation(path:string){
        console.log("path",path);
    }
    render() {
        const tabHover: string = this.state.tabHover;

        return (<>
            <div style={styleSheet.navBar}>
                <div style={styleSheet.logoNav}>
                    <img src={logoImg} width="50px" height="50px" />
                    <ul style={styleSheet.menubar}>
                        <li style={{...styleSheet.list, backgroundColor: tabHover == "projectDashboard" ? "#E0EDF0" : "", color:tabHover == "projectDashboard" ? "#237182" : ""}} onMouseEnter={() => this.handleChangeHoverEffect("projectDashboard")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Project Dashboard</li>
                        <li style={{...styleSheet.list, backgroundColor: tabHover == "Invoices" ? "#E0EDF0" : "", color:tabHover == "Invoices" ? "#237182" : ""}} onMouseEnter={() => this.handleChangeHoverEffect("Invoices")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Invoices</li>
                        <li style={{...styleSheet.list, backgroundColor: tabHover == "chatSystem" ? "#E0EDF0" : "", color:tabHover == "chatSystem" ? "#237182" : ""}} onMouseEnter={() => this.handleChangeHoverEffect("chatSystem")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Chat System</li>
                        <li style={{...styleSheet.list, backgroundColor: tabHover == "Chat" ? "#E0EDF0" : "", color:tabHover == "Chat" ? "#237182" : ""}} onMouseEnter={() => this.handleChangeHoverEffect("Chat")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Chat</li>
                    </ul>
                </div>
                <div style={styleSheet.rightLogo}>
                    <div> <img src={alarm} width="24px" height="24px" /></div>
                    <div style={{ width: "32px", height: "32px", borderRadius: "50px", overflow: "hidden" }}> <img src={profileImg} style={{ objectFit: "cover" }} width="100%" height="100%" /></div>
                    <div> <img src={logout} width="24px" height="24px" /></div>
                </div>
            </div>
            <div style={styleSheet.main}>
                <div style={styleSheet.sidebar as React.CSSProperties}>
                    <div>
                        <div style={{ ...styleSheet.sidebarMenu, borderRadius:"8px", marginTop: "32px", backgroundColor: tabHover == "darkWhite" ? "#E0EDF0" : "" }} onClick={()=>{this.handleNavigation("/darkWhite")}} onMouseEnter={() => this.handleChangeHoverEffect("darkWhite")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.08 7.06 19.44 11 19.93V4.07C7.05 4.56 4 7.92 4 12ZM13 4.07C14.03 4.2 15 4.52 15.87 5H13V4.07ZM18.24 7H13V8H18.92C18.72 7.65 18.49 7.31 18.24 7ZM13 10H19.74C19.82 10.33 19.89 10.66 19.93 11H13V10ZM13 19V19.93C14.03 19.8 15 19.48 15.87 19H13ZM18.24 17H13V16H18.92C18.72 16.35 18.49 16.69 18.24 17ZM13 14H19.74C19.82 13.67 19.89 13.34 19.93 13H13V14Z" fill={tabHover == "darkWhite" ? "#237182" : "#64748B"} />
                            </svg>
                        </div>
                        <div style={{ ...styleSheet.sidebarMenu, borderRadius:"8px", backgroundColor: tabHover == "peoples" ? "#E0EDF0" : "" }} onClick={()=>{this.handleNavigation("/peoples")}} onMouseEnter={() => this.handleChangeHoverEffect("peoples")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.99 8C11.99 9.66 10.66 11 9 11C7.34 11 6 9.66 6 8C6 6.34 7.34 5 9 5C10.66 5 11.99 6.34 11.99 8ZM18.99 9.5C18.99 10.88 17.88 12 16.5 12C15.12 12 14 10.88 14 9.5C14 8.12 15.12 7 16.5 7C17.88 7 18.99 8.12 18.99 9.5ZM16.5 14C14.67 14 11 14.92 11 16.75V18C11 18.55 11.45 19 12 19H21C21.55 19 22 18.55 22 18V16.75C22 14.92 18.33 14 16.5 14ZM2 16.5C2 14.17 6.67 13 9 13C9.66 13 10.5 13.1 11.37 13.28C9.33 14.41 9 15.9 9 16.75V19H3C2.45 19 2 18.55 2 18V16.5Z" fill={tabHover == "peoples" ? "#237182" : "#64748B"} />
                            </svg></div>
                        <div style={{ ...styleSheet.sidebarMenu, borderRadius:"8px", backgroundColor: tabHover == "Calendar" ? "#E0EDF0" : "" }} onClick={()=>{this.handleNavigation("/Calendar")}} onMouseEnter={() => this.handleChangeHoverEffect("Calendar")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3608 3.81818H19.2699C20.2699 3.81818 21.0881 4.63636 21.0881 5.63636V20.1818C21.0881 21.1818 20.2699 22 19.2699 22H4.72443C3.72443 22 2.90625 21.1818 2.90625 20.1818V5.63636C2.90625 4.63636 3.72443 3.81818 4.72443 3.81818H5.63352V2.90909C5.63352 2.40909 6.04261 2 6.54261 2C7.04261 2 7.4517 2.40909 7.4517 2.90909V3.81818H16.5426V2.90909C16.5426 2.40909 16.9517 2 17.4517 2C17.9517 2 18.3608 2.40909 18.3608 2.90909V3.81818ZM5.63352 20.1818H18.3608C18.8608 20.1818 19.2699 19.7727 19.2699 19.2727V8.36364H4.72443V19.2727C4.72443 19.7727 5.13352 20.1818 5.63352 20.1818Z" fill={tabHover == "Calendar" ? "#237182" : "#64748B"} />
                            </svg>
                        </div>
                        <div style={{ ...styleSheet.sidebarMenu, borderRadius:"8px", backgroundColor: tabHover == "business" ? "#E0EDF0" : "" }} onClick={()=>{this.handleNavigation("/business")}} onMouseEnter={() => this.handleChangeHoverEffect("business")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8.5C22 7.39 21.11 6.5 20 6.5H16V4.5C16 3.39 15.11 2.5 14 2.5H10C8.89 2.5 8 3.39 8 4.5V6.5H4C2.89 6.5 2.01 7.39 2.01 8.5L2 19.5C2 20.61 2.89 21.5 4 21.5H20C21.11 21.5 22 20.61 22 19.5V8.5ZM14 6.5V4.5H10V6.5H14ZM4 9.5V18.5C4 19.05 4.45 19.5 5 19.5H19C19.55 19.5 20 19.05 20 18.5V9.5C20 8.95 19.55 8.5 19 8.5H5C4.45 8.5 4 8.95 4 9.5Z" fill={tabHover == "business" ? "#237182" : "#64748B"} />
                            </svg>
                        </div>
                        <div style={{ ...styleSheet.sidebarMenu, borderRadius:"8px", backgroundColor: tabHover == "security" ? "#E0EDF0" : "" }} onClick={()=>{this.handleNavigation("/security")}} onMouseEnter={() => this.handleChangeHoverEffect("security")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.19 4.37625L11.19 1.26625C11.7 1.03625 12.3 1.03625 12.81 1.26625L19.81 4.37625C20.53 4.69625 21 5.41625 21 6.20625V10.9062C21 16.4563 17.16 21.6462 12 22.9062C6.84 21.6462 3 16.4563 3 10.9062V6.20625C3 5.41625 3.47 4.69625 4.19 4.37625ZM19 11.8962H12V3.09625L5 6.20625V11.9062H12V20.8363C15.72 19.6863 18.47 16.0163 19 11.8962Z" fill={tabHover == "security" ? "#237182" : "#64748B"} />
                            </svg>
                        </div>
                        <div style={{ ...styleSheet.sidebarMenu, borderRadius:"8px", backgroundColor: tabHover == "folder" ? "#E0EDF0" : "" }} onClick={()=>{this.handleNavigation("/folder")}} onMouseEnter={() => this.handleChangeHoverEffect("folder")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17C9.7 4 10.21 4.21 10.59 4.59L12 6ZM4 17C4 17.55 4.45 18 5 18H19C19.55 18 20 17.55 20 17V9C20 8.45 19.55 8 19 8H5C4.45 8 4 8.45 4 9V17Z" fill={tabHover == "folder" ? "#237182" : "#64748B"} />
                            </svg>
                        </div>
                    </div>
                    <div style={{ marginBottom: "16px" }}>
                        <img src={setting} width="100%" height="100%" />
                    </div>
                </div>
                <main><h1>Hello World</h1></main>
            </div>
        </>)
    }
}
export default Header;

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean |File | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
  placeholder:string;
}



export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}
export interface InputProps {
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  style: {
    backgroundColor: string;
  };
}

// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  first_name:string;
  firstNameError:boolean;
  firstNameErrorMsg:string;
  lastName:string;
  lastNameError:boolean;
  lastNameErrorMsg:string;
  countryCode:string;
  countryCodeError: boolean;
  countryCodeErrorMsg: string;
  phoneNumber:string;
  phoneNumberError:boolean;
  phoneNumberErrorMsg:string;
  companyName:string;
  companyNameError: boolean,
  companyNameErrorMsg: string,
  aboutMeError:boolean,
  aboutMeErrorMsg:string,
  companyNumberError:boolean;
  companyNumberErrorMsg:string;
  vatError:boolean;
  vatErrorMsg:string;
  streetNameError: boolean;
  streetNameErrorMsg: string;
  cityError:boolean;
  cityErrorMsg:string;
  zipcodeError: boolean,
  zipcodeErrorMsg: string,
  stateError: boolean,
  stateErrorMsg: string,
  countryError: boolean,
  countryErrorMsg: string,
  companyLogo: any;
  httpBodyPersonal: any;
  companyLogoError: boolean,
  companyLogoErrorMsg: string,
  inputKey: number,
  formComplete: boolean;
  vat:string;
  street_name:string;
  city:string;
  zip_code:string;
  state:string;
  country:string;
  company_number:string;
  validations: { validateFirstName: boolean; validateLastName: boolean; validatePhoneNumber: boolean; validateCompanyName: boolean; validateCompanyLogo: boolean; validateCountryCode: boolean
    validateCompanyNumber: boolean,
    validateAboutMe:boolean,
    street_nameMatch: boolean,
    validateVAT: boolean,
    cityMatch: boolean,
    zipcodeMatch: boolean,
    stateMatch: boolean,
    countryMatch: boolean,

  };
  imageData: {
    image: string;
    size:number;
    file_name: string;
    content_type: string;
   
  };
  editImageData:{
    image: string;
    size:number | string;
    file_name: string;
    content_type: string;
  };
  errors:{
    first_name:string;
    phoneNumber:string;
    companyName:string;
    lastName:string;
    countryCode:string;
    company_number:string;
    firstNameError: string;
    lastNameError: string;
    phoneNumberError: string;
    companyNameError: string;
    aboutMeError:string;
    companyLogoError: string;
    countryCodeError: string
    companyNumberError:string,
    vat:string;
    street_name:string;
    city:string;
    zip_code:string;
    state:string;
    country:string;
    vatError:string,}
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  fullName: string;
  email: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;

  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  biography: string;
  video: string;
  attributes: IAttribute[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  edtEmailEnabled: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;
  selectedCountry:string,
  selectedState:string,
  selectedCity:string
  saveButtonDisable: boolean;
  enableField: boolean;

  editPersonalInformation: {
    first_name: string,
    last_name: string,
    phone_number: string,
    company_logo: string | File,
    company_name: string,
    company_number: string,
    vat: string,
    street_name: string,
    city: string,
    zip_code: string,
    state: string,
    country: string
    role_id: string,
    job_title: string,
    about_me: string,
    country_code: string,};
  editDetailToggle: boolean,
  editError: {
    first_name: string,
    last_name: string,
    phone_number: string,
    role_id: string,
    job_title: string,
    company_name: string,
    company_number: string,
    vat: string,
    street_name: string,
    city: string,
    zip_code: string,
    state: string,
    country: string
    about_me: string,
    country_code: string,},
  editPicToggle:boolean,
  tostifymessage:string,
  status:string
  toggleEditPersonalInfoAndBusinessInfo:boolean,
  popUpToggle:boolean
  previewImage:string,
  messageToggle:boolean,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableClientProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileFieldsCallId: string = "";
  getCustomizableProfileCallId: string = "";
  postCreateMemberCallId:string= "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;
  getCustomizableBusinessProfileCallId:string="";
  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getPersonalInformation:string="";
  editPersonalInformationId:string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";
  createPersonalProfilesApiCallID: any;
  apiCallMessageUpdateProfileRequestId: string = "";
 
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
     
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      first_name:"",
      firstNameError:false,
      firstNameErrorMsg:"",
      lastName:"",
      lastNameError:false,
      lastNameErrorMsg:"",
      countryCode:"+44",
      countryCodeError: false,
      countryCodeErrorMsg: '',
      phoneNumber: "",
      phoneNumberError:false,
      phoneNumberErrorMsg:'',
      companyName:"",
      aboutMe:'',
      companyNameError: false,
      aboutMeError:false,
      aboutMeErrorMsg:'',
      companyNameErrorMsg: '',
      companyLogo: null,
      selectedCountry:'',
      selectedState:'',
      selectedCity:'',
      vatError:false,
      vatErrorMsg:'',
       companyNumberError:false,
       companyNumberErrorMsg:'',
      streetNameError: false,
      streetNameErrorMsg: '',
       cityError:false,
     cityErrorMsg:'',
      zipcodeError: false,
      zipcodeErrorMsg: '',
      stateError: false,
      stateErrorMsg: '',
     countryError:false,
      countryErrorMsg: '',
      httpBodyPersonal: {},
      inputKey: Date.now(),
      companyLogoErrorMsg: '',
      company_number:"",
      formComplete: false,
      companyLogoError: false,
      validations:
        { validateFirstName: false, 
        validateLastName: false,
        validatePhoneNumber: false,
        validateCompanyName: false,
        validateCompanyLogo: false,
          validateCountryCode: false ,
        validateCompanyNumber: false,
        validateAboutMe:false,
        street_nameMatch: false,
        validateVAT: false,
        cityMatch: false,
        zipcodeMatch: false,
        stateMatch: false,
        countryMatch: false,
      },
      editImageData:{
        file_name: "",
        content_type: "",
        image: "",
        size:""
      },
      imageData: {
        file_name: '',
        content_type: '',
        image: '',
        size: 0
      },
     
      vat:"",
      street_name:"",
      country:"",
      errors:{
        first_name:"",
        lastName:"",
        countryCode:"",
        phoneNumber: "",
        companyName:"",
        // companyLogo:null,
        company_number:"",
        vat:"",
        street_name:"",
        city:"",
        zip_code:"",
        state:"",
        aboutMeError:"",
        country:"",
        firstNameError: "",
        lastNameError: "",
        phoneNumberError: '',
        companyNameError: '',
        companyLogoError: '',
        countryCodeError: '',
        companyNumberError:"",
        vatError:"",
      },
      state:"", 
      city:"",
      zip_code:"",
      profile: {},
      profileImageUrl: "",
      fields: [],
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      fullName: "",
      email: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      userAge: 0,
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,
      imageModalVisible: false,
     
      editPersonalInformation: {
        job_title: "",
        about_me: "",
        country_code: "",
        company_logo: "",
        company_name: "",
        company_number: "",
        vat: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        role_id: "",
        street_name: "",
        city: "",
        zip_code: "",
        state: "",
        country: ""
      },
      editError: {
        first_name: "",
        last_name: "",
        phone_number: "",
        about_me: "",
        country_code: "",
        company_name: "",
        company_number: "",
        vat: "",
        street_name: "",
        city: "",
        zip_code: "",
        state: "",
        country: "",
        role_id: "",
        job_title: "",
     
      },
      editDetailToggle: true,
    
      editPicToggle:true,
      tostifymessage:"",
      status:"",
      toggleEditPersonalInfoAndBusinessInfo:true,
      popUpToggle:false,
      previewImage:"",
      messageToggle:false,
      
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount() ;
    // Customizable Area Start
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    // if (this.checkSession(message)) return;
    runEngine.debugLog("Message Received", message);

    // Customizable Area End
  }

  // Customizable Area Start


 
  updateFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ first_name: value }, this.validateFirstName);
  };

  validateFirstName = () => {
    const { first_name } = this.state;
    let firstNameError = false;
    let firstNameErrorMsg = '';
    let validations = { ...this.state.validations, validateFirstName: true };
    if (!first_name) {
      firstNameError = true ;
      firstNameErrorMsg = 'Enter the first name';
      validations.validateFirstName = false;}
    else if (!/^[a-zA-Z0-9\s]+$/.test(first_name)) 
      {
      firstNameError = true;
      firstNameErrorMsg = 'First name should be alphanumeric'
      validations.validateFirstName = false; } 
      else {
      window.localStorage.setItem("first_name", this.state.first_name);}
    this.setState({
      firstNameError,firstNameErrorMsg,
      validations,
      errors: { ...this.state.errors, firstNameError: firstNameErrorMsg }}, this.validateForm);
  };
  updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ lastName: value }, this.validateLastName);};

  validateLastName = () => {
    const { lastName } = this.state;
    let lastNameError = false;
    let lastNameErrorMsg = '';
    let validations = { ...this.state.validations, validateLastName: true };
    if (!lastName) {
      lastNameError = true;
      lastNameErrorMsg = 'Enter the last name';
      validations.validateLastName = false; }
    else if (!/^[a-zA-Z0-9\s]+$/.test(lastName)) {
      lastNameError = true;
      lastNameErrorMsg = 'Last name should be alphanumeric';
      validations.validateLastName = false; } 
      else {
      window.localStorage.setItem("lastName", this.state.lastName);}
    this.setState({ lastNameError, lastNameErrorMsg, validations, errors: { ...this.state.errors, lastNameError: lastNameErrorMsg } }, 
      this.validateForm);};


  validateCountryCode = () => {
    const { countryCode } = this.state;
    let countryCodeError = false;
    let countryCodeErrorMsg = '';

    if (!countryCode) {
      countryCodeError = true;
      countryCodeErrorMsg = 'Select the country code';
    }

    window.localStorage.setItem("countryCode", this.state.countryCode)
    this.setState({ countryCodeError, countryCodeErrorMsg }, this.validateForm);
  };

  updateCountryCode = (event: { target:{ value: any; };}) => {
    const newCountryCode = event.target.value;
    this.setState({ countryCode: newCountryCode, 
      phoneNumber: '' }, this.validatePhoneNumber);};


  updatePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const { countryCode } = this.state;
    const validCountryCodes = [
      { code: 'GB', dial_code: '+44', phone_length: 10 },
      { code: 'CA', dial_code: '+123', phone_length: 7 },
      { code: 'DE', dial_code: '+49', phone_length: 10 },
      { code: 'ES', dial_code: '+34', phone_length: 9 },
      { code: 'RU', dial_code: '+7', phone_length: 10 },
      { code: 'CHN', dial_code: '+86', phone_length: 11 },
      { code: 'SA', dial_code: '+966', phone_length: 9 },
      { code: 'PRT', dial_code: '+351', phone_length: 9 },];
  
    const selectedCountryy = validCountryCodes.find(country => country.dial_code === countryCode);
    const maxLengthh = selectedCountryy ? selectedCountryy.phone_length : 10;
  
    if (value.length <= maxLengthh) {
      this.setState({ phoneNumber: value }, 
        this.validatePhoneNumber);
    } else {
      this.setState({ phoneNumber: value.slice(0, maxLengthh) }, 
      this.validatePhoneNumber);
    }
  };
  
  validatePhoneNumber = () => {
    const { phoneNumber, countryCode } = this.state;
    let phoneNumberError = false;
    let phoneNumberErrorMsg = '';
    let validations = { ...this.state.validations, validatePhoneNumber: true };
    const validCountryCodes = [
      { code: 'AD', dial_code: '+376', phone_length: 6 },
      { code: 'AE', dial_code: '+971', phone_length: 9 },
      { code: 'AF', dial_code: '+93', phone_length: 9 },
      { code: 'AG', dial_code: '+1-268', phone_length: 7 },
      { code: 'AI', dial_code: '+1-264', phone_length: 7 },
      { code: 'AL', dial_code: '+355', phone_length: 8 },
      { code: 'AM', dial_code: '+374', phone_length: 8 },
      { code: 'AO', dial_code: '+244', phone_length: 9 },
      { code: 'AR', dial_code: '+54', phone_length: 10 },
      { code: 'AT', dial_code: '+43', phone_length: 10 },
      { code: 'AU', dial_code: '+61', phone_length: 9 },
      { code: 'AW', dial_code: '+297', phone_length: 7 },
      { code: 'AZ', dial_code: '+994', phone_length: 9 },
      { code: 'BA', dial_code: '+387', phone_length: 8 },
      { code: 'BB', dial_code: '+1-246', phone_length: 7 },
      { code: 'BD', dial_code: '+880', phone_length: 10 },
      { code: 'BE', dial_code: '+32', phone_length: 9 },
      { code: 'BF', dial_code: '+226', phone_length: 8 },
      { code: 'BG', dial_code: '+359', phone_length: 8 },
      { code: 'BH', dial_code: '+973', phone_length: 8 },
      { code: 'BI', dial_code: '+257', phone_length: 7 },
      { code: 'BJ', dial_code: '+229', phone_length: 8 },
      { code: 'BL', dial_code: '+590', phone_length: 10 },
      { code: 'BM', dial_code: '+1-441', phone_length: 7 },
      { code: 'BN', dial_code: '+673', phone_length: 7 },
      { code: 'BO', dial_code: '+591', phone_length: 8 },
      { code: 'BQ', dial_code: '+599', phone_length: 10 },
      { code: 'BR', dial_code: '+55', phone_length: 11 },
      { code: 'BS', dial_code: '+1-242', phone_length: 7 },
      { code: 'BT', dial_code: '+975', phone_length: 8 },
      { code: 'BV', dial_code: '+47', phone_length: 8 },
      { code: 'BW', dial_code: '+267', phone_length: 7 },
      { code: 'BY', dial_code: '+375', phone_length: 9 },
      { code: 'BZ', dial_code: '+501', phone_length: 7 },
      { code: 'CA', dial_code: '+1', phone_length: 10 },
      { code: 'CC', dial_code: '+61', phone_length: 9 },
      { code: 'CD', dial_code: '+243', phone_length: 9 },
      { code: 'CF', dial_code: '+236', phone_length: 7 },
      { code: 'CG', dial_code: '+242', phone_length: 7 },
      { code: 'CH', dial_code: '+41', phone_length: 9 },
      { code: 'CI', dial_code: '+225', phone_length: 10 },
      { code: 'CK', dial_code: '+682', phone_length: 7 },
      { code: 'CL', dial_code: '+56', phone_length: 9 },
      { code: 'CM', dial_code: '+237', phone_length: 9 },
      { code: 'CN', dial_code: '+86', phone_length: 11 },
      { code: 'CO', dial_code: '+57', phone_length: 10 },
      { code: 'CR', dial_code: '+506', phone_length: 8 },
      { code: 'CU', dial_code: '+53', phone_length: 8 },
      { code: 'CV', dial_code: '+238', phone_length: 7 },
      { code: 'CW', dial_code: '+599', phone_length: 10 },
      { code: 'CX', dial_code: '+61', phone_length: 9 },
      { code: 'CY', dial_code: '+357', phone_length: 8 },
      { code: 'CZ', dial_code: '+420', phone_length: 9 },
      { code: 'DE', dial_code: '+49', phone_length: 10 },
      { code: 'DJ', dial_code: '+253', phone_length: 6 },
      { code: 'DK', dial_code: '+45', phone_length: 8 },
      { code: 'DM', dial_code: '+1-767', phone_length: 7 },
      { code: 'DO', dial_code: '+1-809', phone_length: 10 },
      { code: 'DZ', dial_code: '+213', phone_length: 9 },
      { code: 'EC', dial_code: '+593', phone_length: 9 },
      { code: 'EE', dial_code: '+372', phone_length: 7 },
      { code: 'EG', dial_code: '+20', phone_length: 10 },
      { code: 'EH', dial_code: '+212', phone_length: 9 },
      { code: 'ER', dial_code: '+291', phone_length: 7 },
      { code: 'ES', dial_code: '+34', phone_length: 9 },
      { code: 'ET', dial_code: '+251', phone_length: 10 },
      { code: 'FI', dial_code: '+358', phone_length: 12 },
      { code: 'FJ', dial_code: '+679', phone_length: 7 },
      { code: 'FM', dial_code: '+691', phone_length: 7 },
      { code: 'FO', dial_code: '+298', phone_length: 6 },
      { code: 'FR', dial_code: '+33', phone_length: 9 },
      { code: 'GA', dial_code: '+241', phone_length: 7 },
      { code: 'GB', dial_code: '+44', phone_length: 10 },
      { code: 'GD', dial_code: '+1-473', phone_length: 7 },
      { code: 'GE', dial_code: '+995', phone_length: 9 },
      { code: 'GF', dial_code: '+594', phone_length: 10 },
      { code: 'GH', dial_code: '+233', phone_length: 9 },
      { code: 'GI', dial_code: '+350', phone_length: 5 },
      { code: 'GL', dial_code: '+299', phone_length: 7 },
      { code: 'GM', dial_code: '+220', phone_length: 7 },
      { code: 'GN', dial_code: '+224', phone_length: 9 },
      { code: 'GP', dial_code: '+590', phone_length: 10 },
      { code: 'GQ', dial_code: '+240', phone_length: 9 },
      { code: 'GR', dial_code: '+30', phone_length: 10 },
      { code: 'GT', dial_code: '+502', phone_length: 8 },
      { code: 'GU', dial_code: '+1-671', phone_length: 7 },
      { code: 'GW', dial_code: '+245', phone_length: 7 },
      { code: 'GY', dial_code: '+595', phone_length: 10 },
      { code: 'HK', dial_code: '+852', phone_length: 8 },
      { code: 'HM', dial_code: '+672', phone_length: 5 },
      { code: 'HN', dial_code: '+504', phone_length: 8 },
      { code: 'HR', dial_code: '+385', phone_length: 9 },
      { code: 'HT', dial_code: '+509', phone_length: 8 },
      { code: 'HU', dial_code: '+36', phone_length: 9 },
      { code: 'ID', dial_code: '+62', phone_length: 10 },
      { code: 'IE', dial_code: '+353', phone_length: 9 },
      { code: 'IL', dial_code: '+972', phone_length: 9 },
      { code: 'IN', dial_code: '+91', phone_length: 10 },
      { code: 'IO', dial_code: '+246', phone_length: 7 },
      { code: 'IQ', dial_code: '+964', phone_length: 10 },
      { code: 'IR', dial_code: '+98', phone_length: 10 },
      { code: 'IS', dial_code: '+354', phone_length: 7 },
      { code: 'IT', dial_code: '+39', phone_length: 10 },
      { code: 'JM', dial_code: '+1-876', phone_length: 7 },
      { code: 'JO', dial_code: '+962', phone_length: 9 },
      { code: 'JP', dial_code: '+81', phone_length: 10 },
      { code: 'KE', dial_code: '+254', phone_length: 10 },
      { code: 'KG', dial_code: '+996', phone_length: 9 },
      { code: 'KH', dial_code: '+855', phone_length: 8 },
      { code: 'KI', dial_code: '+686', phone_length: 7 },
      { code: 'KM', dial_code: '+269', phone_length: 7 },
      { code: 'KN', dial_code: '+1-869', phone_length: 7 },
      { code: 'KP', dial_code: '+850', phone_length: 9 },
      { code: 'KR', dial_code: '+82', phone_length: 10 },
      { code: 'KW', dial_code: '+965', phone_length: 8 },
      { code: 'KG', dial_code: '+996', phone_length: 9 },
      { code: 'LA', dial_code: '+856', phone_length: 8 },
      { code: 'LB', dial_code: '+961', phone_length: 8 },
      { code: 'LC', dial_code: '+1-758', phone_length: 7 },
      { code: 'LI', dial_code: '+423', phone_length: 7 },
      { code: 'LK', dial_code: '+94', phone_length: 10 },
      { code: 'LR', dial_code: '+231', phone_length: 7 },
      { code: 'LS', dial_code: '+266', phone_length: 8 },
      { code: 'LT', dial_code: '+370', phone_length: 8 },
      { code: 'LU', dial_code: '+352', phone_length: 9 },
      { code: 'LV', dial_code: '+371', phone_length: 8 },
      { code: 'LY', dial_code: '+218', phone_length: 9 },
      { code: 'MA', dial_code: '+212', phone_length: 9 },
      { code: 'MC', dial_code: '+377', phone_length: 7 },
      { code: 'MD', dial_code: '+373', phone_length: 8 },
      { code: 'ME', dial_code: '+382', phone_length: 8 },
      { code: 'MF', dial_code: '+590', phone_length: 10 },
      { code: 'MG', dial_code: '+261', phone_length: 9 },
      { code: 'MH', dial_code: '+692', phone_length: 7 },
      { code: 'MK', dial_code: '+389', phone_length: 8 },
      { code: 'ML', dial_code: '+223', phone_length: 8 },
      { code: 'MM', dial_code: '+95', phone_length: 9 },
      { code: 'MN', dial_code: '+976', phone_length: 8 },
      { code: 'MO', dial_code: '+853', phone_length: 8 },
      { code: 'MP', dial_code: '+1-670', phone_length: 7 },
      { code: 'MQ', dial_code: '+596', phone_length: 10 },
      { code: 'MR', dial_code: '+222', phone_length: 8 },
      { code: 'MS', dial_code: '+1-664', phone_length: 7 },
      { code: 'MT', dial_code: '+356', phone_length: 8 },
      { code: 'MU', dial_code: '+230', phone_length: 8 },
      { code: 'MV', dial_code: '+960', phone_length: 8 },
      { code: 'MW', dial_code: '+265', phone_length: 9 },
      { code: 'MX', dial_code: '+52', phone_length: 10 },
      { code: 'MY', dial_code: '+60', phone_length: 10 },
      { code: 'MZ', dial_code: '+258', phone_length: 9 },
      { code: 'NA', dial_code: '+264', phone_length: 9 },
      { code: 'NC', dial_code: '+687', phone_length: 8 },
      { code: 'NE', dial_code: '+227', phone_length: 8 },
      { code: 'NF', dial_code: '+672', phone_length: 5 },
      { code: 'NG', dial_code: '+234', phone_length: 10 },
      { code: 'NI', dial_code: '+505', phone_length: 8 },
      { code: 'NL', dial_code: '+31', phone_length: 9 },
      { code: 'NO', dial_code: '+47', phone_length: 8 },
      { code: 'NP', dial_code: '+977', phone_length: 10 },
      { code: 'NR', dial_code: '+674', phone_length: 7 },
      { code: 'NU', dial_code: '+683', phone_length: 7 },
      { code: 'NZ', dial_code: '+64', phone_length: 9 },
      { code: 'OM', dial_code: '+968', phone_length: 8 },
      { code: 'PA', dial_code: '+507', phone_length: 7 },
      { code: 'PE', dial_code: '+51', phone_length: 9 },
      { code: 'PF', dial_code: '+689', phone_length: 10 },
      { code: 'PG', dial_code: '+675', phone_length: 7 },
      { code: 'PH', dial_code: '+63', phone_length: 10 },
      { code: 'PK', dial_code: '+92', phone_length: 10 },
      { code: 'PL', dial_code: '+48', phone_length: 9 },
      { code: 'PM', dial_code: '+508', phone_length: 10 },
      { code: 'PN', dial_code: '+870', phone_length: 6 },
      { code: 'PR', dial_code: '+1-787', phone_length: 7 },
      { code: 'PT', dial_code: '+351', phone_length: 9 },
      { code: 'PW', dial_code: '+680', phone_length: 7 },
      { code: 'PY', dial_code: '+595', phone_length: 9 },
      { code: 'QA', dial_code: '+974', phone_length: 8 },
      { code: 'RE', dial_code: '+262', phone_length: 10 },
      { code: 'RO', dial_code: '+40', phone_length: 10 },
      { code: 'RS', dial_code: '+381', phone_length: 9 },
      { code: 'RU', dial_code: '+7', phone_length: 10 },
      { code: 'RW', dial_code: '+250', phone_length: 9 },
      { code: 'SA', dial_code: '+966', phone_length: 9 },
      { code: 'SB', dial_code: '+677', phone_length: 7 },
      { code: 'SC', dial_code: '+248', phone_length: 7 },
      { code: 'SD', dial_code: '+249', phone_length: 9 },
      { code: 'SE', dial_code: '+46', phone_length: 10 },
      { code: 'SG', dial_code: '+65', phone_length: 8 },
      { code: 'SH', dial_code: '+290', phone_length: 7 },
      { code: 'SI', dial_code: '+386', phone_length: 8 },
      { code: 'SJ', dial_code: '+47', phone_length: 8 },
      { code: 'SK', dial_code: '+421', phone_length: 10 },
      { code: 'SL', dial_code: '+232', phone_length: 8 },
      { code: 'SM', dial_code: '+378', phone_length: 8 },
      { code: 'SN', dial_code: '+221', phone_length: 9 },
      { code: 'SO', dial_code: '+252', phone_length: 9 },
      { code: 'SR', dial_code: '+597', phone_length: 7 },
      { code: 'SS', dial_code: '+211', phone_length: 9 },
      { code: 'ST', dial_code: '+239', phone_length: 7 },
      { code: 'SV', dial_code: '+503', phone_length: 8 },
      { code: 'SX', dial_code: '+1-721', phone_length: 7 },
      { code: 'SY', dial_code: '+963', phone_length: 9 },
      { code: 'SZ', dial_code: '+268', phone_length: 7 },
      { code: 'TC', dial_code: '+1-649', phone_length: 7 },
      { code: 'TD', dial_code: '+235', phone_length: 8 },
      { code: 'TF', dial_code: '+262', phone_length: 10 },
      { code: 'TG', dial_code: '+228', phone_length: 8 },
      { code: 'TH', dial_code: '+66', phone_length: 9 },
      { code: 'TJ', dial_code: '+992', phone_length: 9 },
      { code: 'TK', dial_code: '+690', phone_length: 7 },
      { code: 'TL', dial_code: '+670', phone_length: 9 },
      { code: 'TM', dial_code: '+993', phone_length: 8 },
      { code: 'TN', dial_code: '+216', phone_length: 8 },
      { code: 'TO', dial_code: '+676', phone_length: 7 },
      { code: 'TR', dial_code: '+90', phone_length: 10 },
      { code: 'TT', dial_code: '+1-868', phone_length: 7 },
      { code: 'TV', dial_code: '+688', phone_length: 7 },
      { code: 'TZ', dial_code: '+255', phone_length: 10 },
      { code: 'UA', dial_code: '+380', phone_length: 9 },
      { code: 'UG', dial_code: '+256', phone_length: 9 },
      { code: 'US', dial_code: '+1', phone_length: 10 },
      { code: 'UY', dial_code: '+598', phone_length: 8 },
      { code: 'UZ', dial_code: '+998', phone_length: 9 },
      { code: 'VA', dial_code: '+379', phone_length: 5 },
      { code: 'VC', dial_code: '+1-784', phone_length: 7 },
      { code: 'VE', dial_code: '+58', phone_length: 10 },
      { code: 'VN', dial_code: '+84', phone_length: 10 },
      { code: 'VU', dial_code: '+678', phone_length: 7 },
      { code: 'WF', dial_code: '+681', phone_length: 7 },
      { code: 'WS', dial_code: '+685', phone_length: 7 },
      { code: 'YE', dial_code: '+967', phone_length: 9 },
      { code: 'YT', dial_code: '+262', phone_length: 10 },
      { code: 'ZA', dial_code: '+27', phone_length: 10 },
      { code: 'ZM', dial_code: '+260', phone_length: 9 },
      { code: 'ZW', dial_code: '+263', phone_length: 9 },
    ];
    
  
    const selectedCountryy = validCountryCodes.find(country => country.dial_code === countryCode);
    const requiredLengthh = selectedCountryy ? selectedCountryy.phone_length : 10;
  
    if (!phoneNumber) {
      phoneNumberError = true;
      phoneNumberErrorMsg = 'Enter the phone number';
      validations.validatePhoneNumber = false;
    } else if (phoneNumber.length !== requiredLengthh){
      phoneNumberError = true;
      phoneNumberErrorMsg = `Phone number must be ${requiredLengthh} digits for the selected country`;
      validations.validatePhoneNumber = false;
    } else {
      window.localStorage.setItem("phoneNumber", phoneNumber);}
    this.setState({ phoneNumberError, phoneNumberErrorMsg, validations }, 
      this.validateForm);
  };
  

  updateAboutMe = (event: { target: { value: any; }; }) => {
    const { value } = event.target;
    this.setState({ aboutMe: value }, this.validateAboutMe);
  };

  validateAboutMe = () => {
    const { aboutMe } = this.state;
    let aboutMeError = false;
    let aboutMeErrorMsg = '';
    let validations = { ...this.state.validations, validateAboutMe: true };
  
    if (!aboutMe) {
        aboutMeError = true;
        aboutMeErrorMsg = 'Write about yourself';
      validations.validateAboutMe = false;
    } 
    else if (!/^[a-zA-Z\s]*$/.test(aboutMe)) {
      aboutMeError = true;
      aboutMeErrorMsg = 'Only letters and spaces are allowed';
      validations.validateAboutMe = false;
  } else {
      window.localStorage.setItem("aboutMe", this.state.aboutMe);
    }
  
    this.setState({ aboutMeError, aboutMeErrorMsg, validations }, this.validateForm);
  };
 
  getHelperText = (error: any, errorMsg: any) => {
    return error ? errorMsg : '';
  };

  validateForm = () => {
    const { first_name, lastName, countryCode, phoneNumber, aboutMe } = this.state;
    const formComplete = first_name !== '' && lastName !== '' && countryCode !== '' && phoneNumber !== '' && aboutMe !== '';
    this.setState({ formComplete });
  };

  // Customizable Area End
}

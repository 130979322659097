export const leftBackground = require("../assets/leftBackground2.png");
export const leftScreen= require("../assets/leftscreen.png");
export const green_tick = require("../assets/green_tick.png");
export const group_active_step_1 = require("../assets/group_active_step_1.png");
export const group_active_step_2 = require("../assets/group_active_step_2.png");
export const flag = require("../assets/flag.png");
export const filelogo = require("../assets/filelogo_.png");
export const browse_icon = require("../assets/browse_icon.png");
export const upload_icon = require("../assets/upload_icon.png");
export const delete_icon = require("../assets/delete_icon.png");
export const slider2 = require("../assets/slider2.png");
export const backicon = require("../assets/backicon.svg");
export const dropDown = require("../assets/dropDown.png")
export const upload = require("../assets/upload.png");
export const logout = require("../assets/exitSvg.svg"); 
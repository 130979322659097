import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import ChatMemberController, { Props2 } from "./ChatMemberController";
import styled from "@emotion/styled";

export default class ChatMember extends ChatMemberController {
  constructor(props: Props2) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
       <MemberListWrapper >

        {
       this.state.channalMembertList.map((project:any,index:number)=>(
         <Box style={{padding:"10px 0px",maxWidth:"600px"}}>
           <Box  style={{display:"flex", alignItems: "center" ,justifyContent: "space-between"}}>
             <Box style={{display:"flex", alignItems: "center"}}>
             {project.attributes.account.profile_picture_url ? (
                        <img
                            src={project.attributes.account.profile_picture_url}
                            alt={`${project.attributes.account.first_name} ${project.attributes.account.last_name}`}
                            width="44px"
                            height="44px"
                            style={{ borderRadius: "50%", marginRight: "10px" }}
                        />
                    ) : (
                        <Box
                            data-test-id={`project-name-${index}`}
                            style={webStyles.projectBoxText}
                        >
                            {project.attributes?.account?.first_name?.charAt(0)}
                            {project.attributes?.account?.last_name?.charAt(0)}
                        </Box>
                    )}
               <Typography className="rece-user">{project.attributes.account.first_name}</Typography>
             </Box>
             {project.attributes.account.role_id === "owner" || project.attributes.account.role_id === "client" ? (
  <Typography
    style={{
      color:"#FFFFFF",
      fontWeight: 700,
      fontSize:'12px',
      fontFamily:'Poppins',
      background: project.attributes.account.role_id === "owner" ? "#237182" : "#325962", 
      padding: "2px 8px",
      borderRadius: "20px", 
    }}
  >
    {project.attributes.account.role_id}
  </Typography>
) : (
<Commonicondesign onClick={this.removemember.bind(this, project.attributes)}>

    <PersonRemoveOutlinedIcon style={{ width: "28px", height: "28px", color: "#475569" }} />
  </Commonicondesign>
)}

          </Box>
        </Box>
                ))
        }
      </MemberListWrapper>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  createButtonView: {
    padding: "10px 0px",
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
  },
  projectBoxTextActive:{
    borderRadius:'8px',
    width:'44px',
    height:'44px',
    justifyContent:'center',
    alignItems:'center',
   color:'#FFFFFF',
   fontSize:'18px',
   fontFamily:'Poppins',
     backgroundColor:'#237182',
    display:'flex',
  cursor:'pointer',
  textTransform:"uppercase" as "uppercase",
   fontWeight:700,
},
projectBoxText:{
  justifyContent:'center',
  width:'44px',
  display:'flex',
  alignItems:'center',
 color:'#ABCDD5',
 fontSize:'18px',
 fontWeight:700,
 fontFamily:'Poppins',
 cursor:'pointer',
  height:'44px'
  ,borderRadius:'8px',
  backgroundColor:'#D3E4E9',
textTransform:"uppercase" as "uppercase"
}
 
};
const Commonicondesign = styled(Box)({
    cursor:'pointer',
    padding:"3px",
    "&:hover": {
      background: "#D3E4E9",
      borderRadius: "8px",
    
    },
    })
const MemberListWrapper = styled(Box)({
  height: "calc(100vh - 365px)",
    padding:"0 8px",
    overflow:"scroll",
    overflowY: "scroll", 
    overflowX: "hidden", 
    "& .receive_msg":{
      paddingLeft:"44px",
      maxWidth: "600px",
      fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
      
    },
    "& .rece-user":{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    marginLeft:"10px",
    color:"#0F172A",
    },
    "& .rece-msg_time":{
      fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color:"#0F172A",
    paddingLeft:"6px"
    },
      scrollbarWidth: "auto", 
      scrollbarColor:" #CBD5E1 #F1F5F9", 
    
       "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#CBD5E1",
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#F1F5F9",
      },
    
    
    
    });
// Customizable Area End

// Customizable Area Start
import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  IconButton, MenuItem,
  Box,Typography,Button,InputAdornment, Grid, Tabs,Divider, Tab, Switch, TextField, Menu, AppBar, Toolbar,
  DialogActions,
  Dialog,
  Chip,
} from "@material-ui/core";
import { withStyles, Theme, createStyles, createTheme } from '@material-ui/core/styles';
import { styled, ThemeProvider } from "@material-ui/styles";
import { List,Search, ExpandMore,MoreVert, AddCircleOutline, EditSharp, ControlPointDuplicateSharp, DeleteOutline, Clear} from '@material-ui/icons';
import MessageBox from "../../../components/src/messageBox";
import ProjectPortfolioController, {
  Props
} from "./ProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { addProjectFrame, DraftIcon, ProposedIcon, OngoingIcon, PausedIcon, CompletedIcon } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },},});
 const styleSheet = 
{logoutButton: {background: "#237182",color: "#FFFFFF",fontSize: "16px",fontWeight:600,
   fontFamily: "Poppins",textTransform: "none",height: "56px",borderRadius: "8px",width: "180px",
   padding: "10px 16px 10px 16px",} as const,
 logoutButton2: 
 {background: "#237182",color: "#FFFFFF",fontSize: "16px",fontFamily: "Poppins",
   textTransform: "none",height: "56px",borderRadius: "8px",width: "121px",
   padding: "10px 16px 10px 16px",fontWeight:600,} as const,
 cancelButton: 
 {backgroundColor: "#E2E8F0",color: "#325962",fontSize: "16px",fontFamily: "Poppins",
   textTransform: "none",height: "56px",borderRadius: "8px",width: "96px",fontWeight:600,} as const,
 title: 
 {
   fontWeight: 700,fontSize: "24px",color: "#0F172A",fontFamily: "Poppins",
   lineHeight: "32px",letterSpacing: "-0.005em"},
  cursorStyle: {cursor: 'pointer'}
  }

export default class ProjectPortfolio extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }
  getTabStyle = (isActiveTab: boolean, index: number): React.CSSProperties => {
    const borderRadius = index === 4 ? "8px" : "25px";
    return {
      minWidth: "auto",
      paddingInline: "20px",
      textTransform: "none",
      borderRadius,
      backgroundColor: isActiveTab ? "#E0EDF0" : "#F8FAFC",
      color: isActiveTab ? "#0F172A" : "#64748B",
      fontWeight: isActiveTab ? "bold" : 600,
      fontFamily: "Poppins",
    };
  };

  ProjectStatus = ({ project }:any) => {
    const statusMap:any = {
        Ongoing: { label: "Ongoing", color: '#3B82F6', backgroundColor: '#DBEAFE' },
        Draft: { label: "Draft", color: '#64748B', backgroundColor: '#F1F5F9' },
        Completed: { label: "Completed", color: '#ECFDF5', backgroundColor: '#10B981' },
        Paused: { label: "Paused", color: '#EF4444', backgroundColor: '#FEE2E2' },
        Proposed: { label: "Proposed", color: '#10B981', backgroundColor: '#D1FAE5' }
    };

    const renderProjectStatus = () => {
        const statusKey:any = project; 
        const status = statusMap[statusKey];
        return status ? (
            <Chip 
                style={{ color: status.color, backgroundColor: status.backgroundColor, fontFamily:'Poppins', fontWeight:400, fontSize:'12px' }} 
                label={status.label} 
            />
        ) : null;
    };

    return (
        <div>
            {renderProjectStatus()}
        </div>
    );
};

filterProjectsByStatus = (projects: any[], activeTab: number | null) => {
  const statusMapping: { [key: number]: string } = {
      0: 'All',
      1: 'Ongoing',
      2: 'Draft',
      3: 'Proposed',
      4: 'Completed',
      5: 'Paused'
  };

  return activeTab && statusMapping[activeTab]
    ? projects.filter((project) => project.status === statusMapping[activeTab])
    : projects;
};

sortProjects = (projects: any, sortType: any) => {
  return projects.sort((a: any, b: any) => {
      if (sortType === 'date_asc') {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      } else if (sortType === 'date_desc') {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      } else if (sortType === 'cost') {
          return a.cost - b.cost;
      }
      return 0;
  });
};
getStatusIcon = (status: string) => {
  switch (status) {
    case "Draft":
      return <img src={DraftIcon} alt="Draft Icon" />;

    case "Proposed":
      return <img src={ProposedIcon} alt="Proposed Icon" />;

    case "Completed":
      return <img src={CompletedIcon} alt="Completed Icon" />;

    case "Paused":
      return <img src={PausedIcon} alt="Paused Icon" />;

    case "Ongoing":
      return <img src={OngoingIcon} alt="Ongoing Icon" />;

    default:
      return null;
  }
};
handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ searchText: event.target.value });
};


getFilteredAndSortedProjects = () => {
  const { projects, activeTab, sortTypeValue, searchText } = this.state;

  const filteredBySearch = projects.filter(
    (project) =>
      project.title &&
      project.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredByTab = this.filterProjectsByStatus(filteredBySearch, activeTab);
  return this.sortProjects(filteredByTab, sortTypeValue);
};

  navigatingBasedOnStatus = (project: {id: string, status: string, draft: boolean, files_uploaded: boolean}) => {
    switch (project.status){
      case 'Draft':
        this.handleOpenDraft(project.id, project.draft, project.files_uploaded)
        break
      case 'Ongoing':
        this.navigateToProjectDashboard(project.id);
        break
      default:
        break
    }
  }

  render() {

   const sortedAndFilteredProjects = this.getFilteredAndSortedProjects();
    const tabs = [
        { label: "All", index: 0 },
        { label: "Ongoing", index: 1 },
        { label: "Draft", index: 2 },
        { label: "Proposed", index: 3 },
        { label: "Completed", index: 4 },
        { label: "Paused", index: 5 },
    ];
   
    
    const renderTab = ({ label, index }: { label: string; index: number }) => {
      const isActiveTab = this.state.activeTab === index;
      const tabStyle = this.getTabStyle(isActiveTab, index);
    
      return <Tab key={label} label={label} style={tabStyle} />;
    };
 

    

    return (
      <ThemeProvider theme={theme}>
        <Box sx={portfolioStyles.mainCont}>
        <MyProfileHeader navigation={this.props.navigation} handleDialogOpen={this.handleOpenDialogLeave}  tabName={'Projects'}>
        {this.state.messageToggle && <MessageBox data-test-id="toastMessage" message={this.state.tostifymessage} status={this.state.status}/>}
        {this.state.openDialogLeave && <Dialog PaperProps={{
                      style: {
                        width: '584px',
                        height: '245px',
                        borderRadius: "16px",
                        padding: "40px 32px 40px 32px",
                        color: "#FFFFFF"
                      }
                    }} open={this.state.openDialogLeave} onClose={this.handleCloseDialog}>
                      <Box >
                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}><Typography style={styleSheet.title} >Are you sure you want to duplicate this project?</Typography> <Typography onClick={this.handleCloseDialog} style={{
                          color: "black",
                          width: "20px",
                          marginRight:"15px",
                          marginTop: "5px"
                        }}><Clear /></Typography>
                      
                        </Box>
                        <Box sx={{marginTop:'16px',paddingRight:'50px'}}>
                        <Typography style={{
                           color : '#1E293B',
                           fontFamily: 'Poppins',
                           fontSize: '16px',
                           fontWeight: 400,
                           lineHeight: '24px',
                        }} >All tasks within this project will be duplicated, including their details and associated files.</Typography> 
                        </Box>
                        <DialogActions style={{
                          marginTop: "45px",
                          gap: "10px"
                        }}>
                          <Button style={styleSheet.cancelButton} onClick={this.handleCloseDialog}>
                            Cancel
                          </Button>
                          <Button data-test-id="handleDuplicateProject"  style={styleSheet.logoutButton}  onClick={this.state.handleDuplicateProject ? this.state.handleDuplicateProject : undefined}>
                            Duplicate Project
                          </Button></DialogActions>

                      </Box>
         </Dialog>}
            <CustomTabs position="static" elevation={0}>
                <Toolbar
                    style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        width: "94%",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        marginTop: "24px",
                        borderRadius: "8px",
                    }}
                >
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{backgroundColor: "#F8FAFC", borderRadius: "18px", marginRight: "10px"}}
                    >
                        {tabs.map(renderTab)}
                    </Tabs>
                   <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} /> 

                     <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <List style={{ color: '#334155' }} />
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 600, paddingInline: '4px' }}>List View</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} />
                    <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 400, paddingInline: '4px', fontFamily: 'Poppins' }}>Sort By:</Typography>
                        <CustomDropDown
                            id="outlined-select-currency-native"
                            select
                            value={this.state.sortTypeValue}
                            data-test-id={'sortFilter'}
                            onChange={this.handleSortByChange}
                            SelectProps={{
                                native: true,
                                IconComponent: ExpandMore,
                            }}
                        >
                            {this.state.sortType.map((option: { value: string; label: string }) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </CustomDropDown>
                     </Box>
            
        <Box style={{display:'flex',flexDirection:'column'}}>
        <CustomSearchBox
        data-test-id="searchInputText"
  variant="outlined"
  placeholder="Search here..."
  value={this.state.searchText}
  onChange={(e:any) => this.handleSearchChange(e)}
  error={!!(this.getFilteredAndSortedProjects().length === 0 && !this.state.loading && this.state.searchText)}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <Search style={{ color: '#94A3B8' }} />
      </InputAdornment>
    ),
    endAdornment: (
      this.state.searchText && (
        <InputAdornment style={{marginRight:'-10px'}} position="end">
          <IconButton onClick={() => this.setState({ searchText: '' })}>
            <Clear />
          </IconButton>
        </InputAdornment>
      )
    ),
  }}
/>
{ this.getFilteredAndSortedProjects().length === 0 && !this.state.loading && this.state.searchText && <Box style={{backgroundColor:'#FCE8E5',borderRadius:'24px',padding:'5px',height:'20px',position:'absolute',marginTop:'50px', color:'#DC2626', fontFamily:'Poppins', fontWeight:'600px', fontSize:'12px'}}>No project exists with this name</Box>}
</Box>
                </Toolbar>
            </CustomTabs>
           
            {this.state.projects.length>0 &&
            <Grid container spacing={2} style={{ padding: '28px 32px 15px 32px' }}>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex', minHeight: '200px' }}>
                    <Card elevation={0} style={{
                        width: '100%',
                        height: "100%",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                    }} data-test-id="btnAddProject">
                        <CardContent style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <AddCircleOutline data-test-id="toCreateProject" onClick={() => this.state.userRole !== 'employee' && this.naviagteToCreatePage()} style={{ borderRadius: "8px", backgroundColor: '#237182', padding: '8px', color: '#fff', cursor: "pointer" }} />
                            <Typography style={{ color: '#475569', fontSize: '16px', fontWeight: 600, marginTop: '20px' }}>Create new project</Typography>
                        </CardContent>
                    </Card>
                </Grid>
{
  sortedAndFilteredProjects.map((project:any, index:any) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={project.id} style={{ display: 'flex', height: '214px' }}>
            <Card elevation={0} style={{ display: 'flex', width: '100%', padding: "1.5rem", borderRadius: "8px" }} data-test-id={`projectDetail${index}`}>
                <CardContent style={{
                    display: "flex",
                    gap: "4px",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: '100%',
                    padding: 0,
                }}>
                    {this.state.isEditing[project.id] ? (
                        <TextField
                            value={this.state.tempName[project.id] || ''}
                            onChange={(e:any) => this.handleInputChange(project.id, e)}
                            onBlur={() => this.handleBlur(project.id)}
                            error={!!this.state.errors[project.id]}
                            helperText={this.state.errors[project.id]}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => this.handleKeyDown(project.id, event)} 
                            autoFocus
                            data-test-id="rename-field"
                        />
                    ) : (
                        <Typography 
                            style={{ 
                                overflow: 'hidden', 
                                lineHeight: "24px", 
                                fontSize: '16px', 
                                color: '#0F172A', 
                                fontWeight: 600,
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 2,
                                lineClamp: 2,  
                            }}>
                            {project.title}
                        </Typography>
                    )}
                    <div>
                        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '0.5rem', fontFamily: "Poppins" }}>
                            <Box display={'flex'} sx={{display:'flex',alignItems:'baseline'}}>Status : &nbsp;<this.ProjectStatus project={project.status} /></Box>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '0.5rem', fontFamily: "Poppins" }}>
                            Location: {project.location}
                        </Typography>
                        <Typography style={{ fontSize: '16px', color: '#1E293B', fontWeight: 600 }}>
                            Cost: £ {project.cost}
                        </Typography>
                    </div>
                </CardContent>
                <CardActions disableSpacing style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 0,
                    alignItems: "flex-end",
                }}>
                    <IconButton data-test-id={`moreOptions`} onClick={() => this.toggleProjectMenu(project.id)}>
                        <MoreVert style={{ color: "#475569" }} />
                    </IconButton>
                    {this.state.selectedProjectId === project.id && (
                        <div className="menu" style={{ position: 'absolute', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0,0,0,0.1)', padding: '8px', zIndex: 1000 }}>
                            <MenuItem 
                               data-test-id="handleRenameProject"
                               onClick={(e) => {
                                   e.stopPropagation();
                                   this.handleRenameProject(project.id);
                                   this.setState({ selectedProjectId: null });
                               }}
                               style={{ borderTop: "1px solid #E2E8F0" }}
                            >
                                <EditSharp style={{ height: '18px', marginRight: '4px' }} />
                                Rename
                            </MenuItem>
                            <MenuItem 
                                data-test-id="openDuplicateDialog"
                                onClick={() => this.openDuplicateDialog(project.id)}
                            >
                                <ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />
                                Duplicate
                            </MenuItem>
                            <MenuItem
                                data-test-id="handleDeleteProject"
                                onClick={() => {
                                    this.handleDeleteProject(project.id);
                                    this.setState({ selectedProjectId: null });
                                }}
                                style={{ color: '#DC2626', alignItems: 'flex-start' }}
                            >
                                <DeleteOutline style={{ height: '20px', marginRight: '4px' }} />
                                Delete
                            </MenuItem>
                        </div>
                    )}
                    <Box
                        data-test-id="handleOpenDraft"
                        style={styleSheet.cursorStyle}
                        onClick={() => this.navigatingBasedOnStatus(project)}
                    >
                        {this.getStatusIcon(project.status)}
                    </Box>
                </CardActions>
            </Card>
        </Grid>
    ))
}



                </Grid>}
                {this.state.projects.length==0 && <Grid container spacing={2} style={{ padding: '15px', backgroundColor: '#F0F5F7',display:'contents' }}>
               <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
                <Box>
                  <img src={addProjectFrame} alt=""/>
                </Box>
                <Box>
                  <Typography style={{    fontFamily: 'Poppins',
                  color:'#0F172A',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    textAlign: 'center',}}>No Project yet</Typography>
                </Box>
                <Box>
                <Typography style={{   
                  marginTop:'16px',
                  color:'#0F172A',
                   fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',}}>You haven't created or joined any projects. Start by creating a new project or joining an existing one.</Typography>
                </Box>
                <Box>
                <Button
  data-test-id="handleCreateNewProject"
  onClick={() =>  this.state.userRole !== 'employee' && this.handleCreateNewProject()}
  variant="contained"
  color="primary"
  style={{
    marginTop:'16px',
    backgroundColor: "#237182",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "capitalize",
  }}
>
  Create new project
</Button>
                
                </Box>
               </Box>
            </Grid>}
    </MyProfileHeader>
           
        </Box>
        </ThemeProvider>
    );
}

}

const CustomMenu = styled(Menu)({
  "& .MuiPaper-root": {
    border: "1px solid #E2E8F0",
    width:"190px",
    height: "121px",  
    alignItems: "center",
    display: "grid",
    borderRadius: "8px",
  },
  "& .MuiList-padding": {
    paddingTop:0,
    paddingBottom:0
  },
  "& .MuiListItem-root": {
    margin: "0 4px",
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "#E0EDF0",
    borderRadius: "8px",
  }
});

const CustomSearchBox = styled((TextField))({
  width:'100%',
  "& .MuiOutlinedInput-root": {
    borderRadius:'8px',
    height:'44px',
    [theme.breakpoints.down('md')]:{
     width:'200px'
    },
    [theme.breakpoints.down(1450)]:{
      width:'300px'
     },
     [theme.breakpoints.up(1450)]:{
      width:'538px'
     },
    "& fieldset": {
      borderColor: '#CBD5E1',
    },
    "&:hover fieldset": {
      borderColor: '#CBD5E1',
    },
    "&.Mui-focused fieldset": {
      borderColor: '#CBD5E1',
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 0px",
    fontWeight:400,
    fontSize:'16px',
    fontFamily:'Poppins',
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    }
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(10px, 10px)"
  },
  "& .MuiFormLabel-root": {
    fontSize: '15px',
    color: '#94A3B8'
  }
});

const portfolioStyles= {
  mainCont: {bgcolor: '#F0F5F7', minHeight: '100%'}
}

const CustomTabs = styled((AppBar))({
  width: "100%",
  backgroundColor: "#F0F5F7",
  "& .MuiTab-root":{
    minHeight:'auto'
  },
  "& .MuiTabs-root":{
    minHeight:'auto'
  }
})

const CustomDropDown = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#fff",
    color: "#237182",
  },
  "& .MuiSelect-select": {
    paddingBlock: "10px",
    color: "#237182",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .MuiSvgIcon-root": {
    color: "#237182",
  },
});


const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#325962',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #ffffff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: 'grey',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
  
)(Switch);

// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Box,
  Input,
  Typography,
  Button,
  Menu,
  MenuItem,
  Modal,
  Select as MUISelect,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,


} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";

import ChatController2, { Props2 } from "./ChatController2";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { Close, Search,MoreVert } from "@material-ui/icons";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {leave_icon,cancle, delete_icon,reply_icon,pinpoint_icon,profile,attachment_icon,chatGroupNotFoundImg, chatGroupNotFoundTxt, chatNotFoundImg,
    lockIcon ,channalname_info_icon,success_icon_toster } from "./assets";
import { ToastContainer } from "react-toastify";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import Select, { components } from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'; 
import ChatMember from "./ChatMember.web";






const MainContainer =  styled(Box)({
   width:'100%',
   height:'85vh',
   display:'flex',
});

// Customizable Area End


export default class Chat2 extends ChatController2 {
  constructor(props: Props2) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  handleProjectList = () => (
    this.state.projectList.length > 0 ? (
      <Box>
        {this.state.projectList.map((project, index) => (
          <Box key={project.attributes.id}>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '3px',
                marginTop: '12px',
              }}
            >
              {project.attributes.project_name !== null && (
                <>
                  <Box
                    style={webStyles.projectMainBox}
                    onClick={this.handleProjectId.bind(this, project)}
                  >
                    <Box
                      data-test-id={`project-name-${index}`}
                      style={
                        project.attributes.id === this.state.projectId
                          ? webStyles.projectBoxTextActive
                          : webStyles.projectBoxText
                      }
                    >
                      {project.attributes.project_name.split(' ')[0][0]}
                      {project.attributes.project_name.split(' ').length > 1 &&
                        project.attributes.project_name.split(' ')[1][0]}
                    </Box>
                    {project.attributes.project_name}
                  </Box>
                  <div style={{ cursor:'pointer', display:"flex",alignItems:"center" }}
                        onClick={(event) => this.handleProjectOption(event, project) }
                         data-test-id="handleProjectOption-id"

                      >
                        {project.attributes.pin_status &&
                                <PushPinOutlinedIcon style={{width:'18px',height:'18px',color:'#0F172A'}}/>

                        }

                        <MoreOption>
                      <MoreVert style={{width:'28px',height:'28px',color:'#475569'}} />
                        </MoreOption>
                    
                      </div>
                      <SpecificationDropDown 
                anchorEl={this.state.projectOption}
                keepMounted
                open={this.state.projectOption}
                elevation={0}
                onClose={this.handleCloseProjectOption}
                autoFocus={false}
                   >
                     <MenuItem onClick={this.handleCloseProjectOption} className=" menuItem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"8px 16px"}}>
           <VolumeOffIcon style={{width:'18px',height:'18px',color:'#0F172A'}} />
           <Typography className="option_list_item">
           Mute Internal Channel
            </Typography>
           </Box>
           </MenuItem>
           <MenuItem onClick={this.handleCloseProjectOption} className=" menuItem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"8px 16px"}}>
           <VolumeOffIcon style={{width:'18px',height:'18px',color:'#0F172A'}} />

           <Typography className="option_list_item">
           Mute External Channel
            </Typography>
           </Box>
           </MenuItem>
           <MenuItem onClick={this.handlePinTOChannal.bind(this)} className=" menuItem" >
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"8px 16px"}}>
           <PushPinOutlinedIcon style={{width:'18px',height:'18px',color:'#0F172A'}}/>
           <Typography className="option_list_item">Pin Project Chat {project.attributes.pin_status}</Typography>
            </Box>
           </MenuItem>
                   
            <MenuItem onClick={this.handleCloseProjectOption} className="deleteitem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"8px 16px"}}>
              <ExitToAppIcon style={{width:'18px',height:'18px',color:'#DC2626'}}/>
              <Typography className="option_list_item">Leave All Project Chats </Typography>
           </Box>
          </MenuItem>
               </SpecificationDropDown>
                </>
              )}
            </Box>
            <Box>
              {project.attributes.id === this.state.projectId && (
                <Box width={'100%'}>
                  {project.attributes.chat_channels.length > 0 && (
                    <Box>
                      {project.attributes.chat_channels.map((channel: { id: React.Key | null | undefined; type: string; }) => (
                        <Box key={channel.id} style={{ padding: '2px 0' }}>
                          {channel.type === 'external' ? (
                            <ProjectchannalList
                              data-test-id={`project-categories-${index}`}
                              onClick={this.handleInternalChannelClick.bind(
                                this,
                                channel
                              )}
                            >
                              External
                              <Box style={webStyles.ext}>EXT.</Box>
                            </ProjectchannalList>
                          ) : (
                            <ProjectchannalList
                              data-test-id={`project-categories2-${index}`}
                              onClick={this.handleInternalChannelClick.bind(
                                this,
                                channel
                              )}
                            >
                              Internal{' '}
                              <img
                                src={lockIcon}
                                alt=""
                                width={'18px'}
                                height={'18px'}
                              />
                            </ProjectchannalList>
                          )}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    ) : (
      <Box>
        <img
          src={chatNotFoundImg}
          alt=""
          width={'312px'}
          height={'312px'}
        />
        <Box style={webStyles.chatGroupNotFound}>
          <p style={webStyles.noMessage}>No Project Chats yet</p>
          <p style={webStyles.noMessageSub}>
            You haven't created or joined any projects. Start by creating a new
            project or joining an existing one.
          </p>
        </Box>
      </Box>
    )
  );
  chatHeader = ()=>{
    return(
      <ChatHeader>
      <Box>
        <Box style={{display:"flex", alignItems: "center"}}>
                      <Typography className="channel_name">
                        {this.capitalizeFirstLetter(this.state.projectnameDetail)}
                        </Typography>
                  <Box style={{cursor:'pointer',}}>
                    <img src={channalname_info_icon} alt="" width={'24px'} height={'24px'}  onClick={this.openRightSection}/>
                  </Box>
        </Box>
        <Typography className="total_member">{this.state.totalmember_in_project} members</Typography>

      </Box>
      <Box className="header_icon_wrapper">
      <Box style={{marginRight:"20px"}}> 
        <Commonicondesign>
        <SearchIcon style={{width:'24px',height:'24px',color:'#475569'}}/>
        </Commonicondesign>
      </Box>
      <Box>

        <Commonicondesign>
            <PushPinOutlinedIcon  style={{width:'24px',height:'24px',color:'#475569'}}/>
        </Commonicondesign>
      </Box>
      </Box>
     </ChatHeader>
    )
  }
  mainChat = ()=>{
    return(
      <MainchatMsg >

        <Box>
          <Box className="today_date">

          <Box className="date_line" >  </Box>
          <Typography className="date">
          16 May, 2024
            </Typography>
          </Box>

        

        {
       this.state.dummyArray.map((item)=>(
         <Box style={{padding:"20px 0px",maxWidth:"600px"}} key={item}>
           <Box  style={{display:"flex", alignItems: "center" ,justifyContent: "space-between"}}>
             <Box style={{display:"flex", alignItems: "center"}}>
              <img src={profile} alt="" width={'32px'} height={'32px'} style={{borderRadius: "20px", marginRight:"10px" }}   />
               <Typography className="rece-user">Tom Wilson</Typography>
                <Typography className="rece-msg_time">2:30 PM</Typography>
             </Box>
             
           <MoreOption >
                      <MoreVert style={{width:'28px',height:'28px',color:'#475569'}}  onClick={this.handleSpeciChange}/>
                      </MoreOption>
           <SpecificationDropDown 
                anchorEl={this.state.anchorElTSpeci}
                keepMounted
                open={this.state.anchorElTSpeci}
                elevation={0}
                onClose={this.handleClose3}
                autoFocus={false}
                   >
           <MenuItem onClick={this.handleClose3} className=" menuItem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"8px 16px"}}>
           <img src={reply_icon} alt="" width={'18px'} height={'18px'} /> 
           <Typography className="option_list_item">
           Reply
            </Typography>
           </Box>
           </MenuItem>
                     <MenuItem onClick={this.handleClose3} className=" menuItem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"8px 16px"}}>
           <img src={pinpoint_icon} alt="" width={'18px'} height={'18px'} /> 
           <Typography className="option_list_item">
           Pin to Channel
            </Typography>
           
            </Box>
           </MenuItem>
            <MenuItem onClick={this.handleClose3} className="deleteitem">
           <Box fontStyle={{display:"flex",alignItems: "center",width:"100%",padding:"8px 16px"}}>
              <img src={delete_icon} alt="" width={'18px'} height={'18px'} />
              <Typography className="option_list_item">

              Delete Message
              </Typography>
           </Box>
          </MenuItem>
               </SpecificationDropDown>
          </Box>
           <Typography className="receive_msg">Morning, team. Quick updates on Cambridge Riverside. Emma, design updates?</Typography>
        </Box>
                ))
              }
              </Box>
      </MainchatMsg>

    )

  }
  InputSendMSG = ()=>{
    return(
      <Box style={{ padding:"11px 20px", display:"flex", position:"relative", background:"white"}}>
    
         <CustomInput
            endAdornment={<Search style={webStyles.searchIcon} />}
            style={webStyles.sendmsgInput}
            placeholder="Type a message here..."
            disableUnderline
            data-test-id="Type a message here..."
            />
          <Box style={{
             position: "absolute",
             right: "110px",
             background: "white",
             top: "21px",
          }}> 

              <img src={attachment_icon} alt="" width={'24px'} height={'24px'} 
               />
          </Box>
         

             <RenameButton
              data-test-id="cancelBtn-id"
              style={webStyles.sendButton}
            >
              Send
            </RenameButton>
      </Box>

    )

  }
  renderSelectInput() {
    return (
      <>
        <Box mt="1.5rem" position="relative"  style={webStyles.InputSearch as any} >
          <style>
            {`
              .basic-multi-select {
                width: 100%;
              }
            `}
          </style>
          <Select
            value={this.state.selectedUsers}
            isMulti
            name="members"
            options={this.filterUsers(
              this.state.users,
              this.state.selectedUsers
            )}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{
              control: (provided: any, state: any) => {
                return {
                  ...provided,
                  display: "flex",
                  flexWrap: "wrap",
                  padding:
                    this.state.selectedUsers.length > 0 ? "0.3rem" : "0.9rem",
                  borderRadius: "8px",
                  paddingLeft: "1rem",
                  boxShadow: "none",
                  
                };
              },
              multiValue: (provided: any) => ({
                ...provided,
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "26px",
                background: "#E0EDF0",
                padding: "6px 12px",
                fontSize: "14px",
                color: "#325962",
                fontFamily: "Poppins",
                fontWeight: 400,
              }),
              input: (provided) => ({
                ...provided,
                margin: 0,
                padding: "0 6px",
                width: "auto",
                flexGrow: 1,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontFamily: "Poppins",
                paddingLeft: "6px",
              }),
            }}
            components={{
              DropdownIndicator: () => null,
              ClearIndicator: () => null,
            }}
            placeholder="Search and add members"
            menuIsOpen={false}
            onChange={(selectedOptions) => {
              this.setState({
                selectedUsers: selectedOptions || [],
              });
            }}
            onInputChange={(inputValue: string | any[]) => {
              if (inputValue.length <= 50) {
                this.handleInputChange(inputValue)
              }
            }}
            inputValue={this.state.inputName}
            data-test-id="select-id"
          />
         

          <SearchIcon
            style={{
              position: "absolute",
              left: "1%",
              top: "54%",
              transform: "translateY(-50%)",
              color: "grey",
            }}
          /> 
          <RenameButton
            data-test-id="create-id"
            style={{ marginLeft: "16px" ,
              padding: "0.7rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#237182",
              color: "#FFF",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.handleAddMemberData}
          >
            Add
          </RenameButton> 
        </Box>
       
      </>
    );
  }


  renderMemberList() {
    const { users, selectedUsers, inputName } = this.state;
    const filteredUsers = this.filterAvailableUsers(
      users,
      selectedUsers,
      inputName
    );
    return (
      <Modellist>
        {filteredUsers.length === 0 ? (
          <Typography
            style={{ color: "#1E293B", fontSize: "16px", textAlign: "center" }}
          >
            No members present
          </Typography>
        ) : (
          <List>
            {filteredUsers?.map((user:any) => (
              <ListItem
                key={user.id}
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemAvatar>
                  <Avatar style={{ background: "#D3E4E9", color: "#ABCDD5" }}>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 700,
                        paddingTop: "4px",
                      }}
                    >
                      {user?.first_name.slice(0, 2).toUpperCase()}
                    </span>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      style={webStyles.ListItemName}
                    >
                      {user?.first_name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" style={webStyles.ListItemEmail}>
                      {user?.email}
                    </Typography>
                  }
                />
                <IconButton
                  data-test-id="memberAdd-id"
                  onClick={this.handleMemberAdd.bind(this, user)}
                  style={{ color: "#000", opacity: 1 }}
                >
                  <AddIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
      </Modellist>
    );
  }

  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
       
        <ThemeProvider theme={theme}>
          <MyProfileHeader navigation={this.props.navigation} tabName="Chat">
          <MainContainer>
          <ProjectChatBar > 
             <Box style={{ display:'flex',flexDirection:'column',gap:'16px'}}>
             <p style={webStyles.projectText}>Project Chats</p>
             <CustomInput
  startAdornment={<Search style={webStyles.searchIcon} />}
  style={webStyles.searchInput}
  placeholder="Search Project Chat"
  onChange={(e) => this.handleSearchInputChange(e.target.value)}
  value={this.state.searchInputText}
  disableUnderline
  data-test-id="search"
/>
             </Box>
             {this.handleProjectList()}
          </ProjectChatBar>
         {
             this.state.projectChannalID > 0 ? 
             <Box style={webStyles.middleScreen}>
              <Box style={{display:"flex",width:"100%"}}>
              <Box style={{width:"100%"}}>
              {   this.chatHeader() }
              { this.mainChat() }
              { this.InputSendMSG() }
               </Box>
               {this.state.isOpenRightSection?

              <RightSection >
                <Box margin={"0 10px"}>

                <Box className="right_header"> 
                <img src={cancle} alt="" width={'24px'} height={'24px'} onClick={  this.cancle}/>
              <Typography style={{color:"#0F172A",
                 fontFamily: "Poppins",
                 fontSize: "18px",
                 fontWeight: 700,
                 lineHeight: "26px",
              }}>
                   Channel Info
                  </Typography>
                 </Box>
                <Box className="ADD_channale_member">
                  <Typography className="channal_name">{this.state.projectChannalname}</Typography>
                     <Typography className="channal_member_no">{this.state.totalmember_in_project} members
                      <AddmemberIcon>
                        <PersonAddAltIcon style={{width:'24px',height:'24px',color:'#237182'}}  onClick={this.OpenADDmemberModel}/>
                      </AddmemberIcon>
                 </Typography>
                 <Box onClick={this.OpenLeaveChatModel.bind(this)}>

                  <Typography className="channal_leave"  > 
                   <img src={leave_icon} alt="" width={'24px'} height={'24px'} />
                    Leave Chat</Typography>
                 </Box>
                </Box>
                <Tab>
  <Box
    onClick={this.handleClick.bind(this, 'member')}
    className={this.state.active === 'member' ? 'active' : 'deactive'}
  >
    Members
  </Box>
  <Box
    onClick={this.handleClick.bind(this, 'media')}
    className={this.state.active === 'media' ? 'active' : 'deactive'}
  >
    Media
  </Box>
  <Box
    onClick={this.handleClick.bind(this, 'files')}
    className={this.state.active === 'files' ? 'active' : 'deactive'}
  >
    Files
  </Box>
</Tab>

{this.state.active === 'member' && (

<Box>
  <ChatMember navigation={undefined} id={""} 
  channalid={this.state.projectChannalID} 
  reRenderCom={this.state.reRenderCom} 
  sendDataToParent={this.handleDataFromChild}

   ></ChatMember>
</Box>
)}
</Box>

   
              </RightSection>:""}
              </Box>
            </Box>
            :
                     <Box style={webStyles.projectChatSideScreen}>
                       <Box>
                      <Box>

                      <img src={chatGroupNotFoundImg} alt="" width={'328px'} height={'309px'} />
                      </Box>
                      <Box>
                        <NoMassage>
                            <Typography className="nomsg_heading">No Messages Yet </Typography>
                             <Typography className="nomsg_subheading">Once you start a conversation, your .</Typography>
                              <Typography className="nomsg_subheading">messages will appear here</Typography>

                       {/* <img src={chatGroupNotFoundTxt} alt="" width={'312px'} height={'76px'} /> */}
                        </NoMassage>
                      </Box>
                       </Box>
                     </Box>  
         }
          </MainContainer>
          </MyProfileHeader>
          <Modal open={this.state.isAddMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyles.renameModal}>
          <Box style={webStyles.modalHeader}>
            <Typography style={webStyles.renameText}>
            Search and Add Member
            </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.handleAddMemberModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" ,
           fontFamily: "poppins",
           fontSize: "16px",
           lineHeight: "24px",
           fontWeight: "400",

          }}>
            Add team members by entering their email addresses below.
          </Typography>
          {this.renderSelectInput()}
          {this.renderMemberList()}
          
        </Box>
      </Modal>
            <CustomToast icon={
              this.state.membereAdded ?
              <img src={success_icon_toster} width={"26px"}  height={"26px"}/>:""
            } />


      <Modal open={this.state.LeavechatModalOpen} style={{ display: "flex" }}>
        <Box style={webStyles.renameModal}>
          <Box style={webStyles.modalHeader}>
            <Typography style={webStyles.renameText}>
            Leave Chat
            </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.closeLeaveChatModel.bind(this)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography  style={webStyles.leave_chat_sub_heading}>
          Are you sure you want to leave this chat?
          </Typography>
          <Box style={{textAlign:"end", marginTop:"24px"}}>
          <RenameButton
            data-test-id="create-id"
            style={{
              padding: "1rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#E0EDF0",
              color: "#325962",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.closeLeaveChatModel}
          >
            Cancel
          </RenameButton> 
          <RenameButton
            style={{ 
              marginLeft: "8px" ,
              padding: "1rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#237182",
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.leavechatConfirm.bind(this)}
            data-test-id="leavechatConfirm"

          >
            Leave Chat
          </RenameButton> 
          </Box>
          
        </Box>
      </Modal>
      <Modal open={this.state.reMoveMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyles.renameModal}>
          <Box style={webStyles.modalHeader}>
            <Typography style={webStyles.renameText}>
            Remove Member
            </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.reMoveMemberModelclose.bind(this)}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography  style={webStyles.leave_chat_sub_heading}>
          Are you sure you want to remove {this.state.reMovememberName} from the chat?          </Typography>
          <Box style={{textAlign:"end", marginTop:"24px"}}>
          <RenameButton
            data-test-id="create-id"
            style={{
              padding: "1rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#E0EDF0",
              color: "#325962",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.reMoveMemberModelclose}
          >
            Cancel
          </RenameButton> 
          <RenameButton
            style={{ 
              marginLeft: "8px" ,
              padding: "1rem",
              borderRadius: "8px",
              fontFamily: "Poppins",
              textTransform: "none",
              background: "#237182",
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: "16px",
            }
          }
            onClick={this.reMoveMemberConfirm.bind(this)}
            data-test-id="leavechatConfirm"

          >
           Confirm
          </RenameButton> 
          </Box>
          
        </Box>
      </Modal>
         
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
Nomessage:{
 textAlign: "center",
    marginTop: "22px",
},
  ListItemName: {
    fontSize: "16px",
    fontFamily: "poppins",
    lineHeight: "24px",
    fontWeight: "400",
  },
  ListItemEmail: {
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "22px",
    fontWeight: "400",
  },
  InputSearch: {
    position: "relative",
    display: "flex",
    justfiyContent: "space-between",
    alignItems: "center",
  },
  renameText: {
    fontFamily: "poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    color:"#0F172A"
  },
  leave_chat_sub_heading:{
    fontFamily: "poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color:"#1E293B",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameModal: {
    margin: "auto",
    height: "fit-content",
    padding: "2.5rem 2rem",
    width: "40vw",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  projectChatBar:{
    width:'22%',
    height:" calc(100vh - 114px)",
    padding:'16px 12px',
    backgroundColor:'white',
    borderRight: "1px solid #E2E8F0",
    overflowY: "scroll", 
    overflowX: "hidden", 
    scrollbarWidth: "auto", 
    scrollbarColor:" #CBD5E1 #F1F5F9", 
  
     "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CBD5E1",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#F1F5F9",
    },
  },

  projectChatSideScreen:{
    width:'100%',
    height:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  searchInput: {
    border: "1px solid #C4CBD4",
    background: "#FCFDFE",
    width:'100%',
    fontWeight:400,
    fontSize:'16px',
    padding:'3px 8px',
    borderRadius:'8px',
    color:'#94A3B8'
  },
  searchIcon: {
    color: "#DADADA",
    width: "24px",
    height: "24px",
    marginRight: "0.5rem",
  },
  projectText:{
    fontSize:'24px',
    fontWeight:700,
    fontFamily:'Poppins',
    padding:0,
    margin:0,
    color:'#0F172A'
  },
  projectBoxTextActive:{
    width:'44px',
    height:'44px'
    ,borderRadius:'8px',
    backgroundColor:'#237182',
   display:'flex',
   justifyContent:'center',
   alignItems:'center',
  color:'#FFFFFF',
  fontSize:'18px',
  fontWeight:700,
 fontFamily:'Poppins',
 cursor:'pointer',
 textTransform:"uppercase" as "uppercase"
},
projectBoxText:{
  width:'44px',
  height:'44px'
  ,borderRadius:'8px',
  backgroundColor:'#D3E4E9',
 display:'flex',
 justifyContent:'center',
 alignItems:'center',
color:'#ABCDD5',
fontSize:'18px',
fontWeight:700,
fontFamily:'Poppins',
cursor:'pointer',
textTransform:"uppercase" as "uppercase"
}
,
projectMainBox:{
  width:'100%',
  display:'flex',
  gap:'16px',
  alignItems:'center',
  fontSize:'14px',
  fontWeight:600,
  fontFamily:"Poppins",
  textTransform:'capitalize' as 'capitalize',
  color:'#0F172A',
  cursor:'pointer',
},
projectExternal:{
 borderRadius:'8px',
 padding: '6px 8px 6px 62px',
 fontWeight:600,
 fontSize:'14px',
 fontFamily:'Poppins',
 color:'#0F172A',
 display:'flex',
 alignItems:'center',
 gap:'5px',cursor:'pointer'

},
projectInternal:{
  borderRadius:'8px',
  padding: '6px 8px 6px 62px',
  fontWeight:600,
  fontSize:'14px',
  fontFamily:'Poppins',
  color:'#0F172A',
  backgroundColor:'#F0F5F7',
   display:'flex',
 alignItems:'center',
 gap:'5px',cursor:'pointer'
},
middleScreen:{
  width:'100%',
},
sendButton: {
  background: "#F1F5F9",
  color: "#64748B",
  fontFamily:'Poppins',
  fontWeight:600,
  fontSize:'16px',
  lineHeight:'24px',
  marginLeft:"10px",
  padding:"10px 16px"
},
sendmsgInput: {
  border: "1px solid #C4CBD4",
  background: "#FCFDFE",
  width:'100%',
  fontWeight:400,
  fontSize:'16px',
  borderRadius:'8px',
  color:'#94A3B8',
  padding:"4px 0px 0px 5px"
  
},
ext:{backgroundColor:'#237182',color:'#FFFFFF',
  borderTopLeftRadius: '16px', 
  borderTopRightRadius: '16px', 
  borderBottomLeftRadius: '16px',  
  borderBottomRightRadius: '16px',
  padding:'0px 8px 1px 8px',
 fontSize:'10px',
 fontWeight:700,
 fontFamily:'Poppins'},
 
 chatGroupNotFound:{
   width:'312px',
   height:'76px',
   display:'flex',
   flexDirection:'column' as 'column',
   gap:'8px'
 },
 noMessage:{
   textAlign: 'center' as 'left' | 'right' | 'center' | 'justify',
   fontFamily:'Poppins',
   fontWeight:600,
   fontSize:'16px',
   lineHeight:'24px',
   margin:0,
   padding:0,
   color:'#1E293B'
 },
 noMessageSub:{
 color:'#475569',
 fontFamily:'Poppins',
 fontSize:'14px',
 fontWeight:400,
 lineHeight:'22px',
 margin:0,
 padding:0,
  textAlign: 'center' as 'left' | 'right' | 'center' | 'justify'
 
 }
};
const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});
const ChatHeader = styled(Box)({
  height:"42px",
  display:'flex',
  justifyContent:'space-between',
  alignItems:"center",
  padding:"20px",
  background:"#FFFFFF",
  "& .channel_name":{
fontFamily: "Poppins",
fontSize: "20px",
fontWeight: 700,
lineHeight: "28px",
color:"#0F172A",
marginRight:"5px",

  },
  "& .total_member":{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
      },

      "& .header_icon_wrapper":{
        display:'flex',
      }

})
const MainchatMsg = styled(Box)({
height: "calc(100vh - 230px)",
padding:"0 20px",
overflowY: "scroll", 
background:"#F1F5F9",
    overflowX: "hidden", 
    "& .today_date":{
     
      position: 'relative',
      zIndex: 1,

    },
    "& .date_line":{
      background: '#CBD5E1',
      textAlign: 'center',
      height: '2px',
    width: '100%',
    marginTop:"15px",

    },
    "& .date":{
      background: '#F1F5F9',
      position: 'absolute',
      left: 0,
      right: 0,
      top: '-19px',
      width: 'fit-content',
      margin: 'auto',
      padding: '10px',
      zIndex: 9999,
      fontFamily: "Poppins",
fontSize: "14px",
fontWeight: 400,
lineHeight: "23px",
    },
"& .receive_msg":{
  paddingLeft:"44px",
  maxWidth: "600px",
  fontFamily: "Poppins",
fontSize: "16px",
fontWeight: 400,
lineHeight: "24px",
color:"#0F172A"
  
},
"& .rece-user":{
fontFamily: "Poppins",
fontSize: "16px",
fontWeight: 600,
lineHeight: "24px",
color:"#0F172A",
},
"& .rece-msg_time":{
  fontFamily: "Poppins",
fontSize: "14px",
fontWeight: 400,
lineHeight: "22px",
color:"#94A3B8",
paddingLeft:"6px"
},
  scrollbarWidth: "auto", 
  scrollbarColor:" #CBD5E1 #F1F5F9", 

   "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CBD5E1",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#F1F5F9",
  },



});
const SpecificationDropDown = styled(Menu)({
  "& .MuiMenu-paper":{
     borderRadius:"10px",
    },
    "& .MuiMenu-list":{
    display : "flex",
    flexDirection:"column",
    alignItems: "start",
    padding:"10px 10px"
  },
  "& .css-1nx3shm-MuiButtonBase-root-MuiMenuItem-root:hover":{
    background:"#E2E8F0",
    borderRadius:"5px",

  },
  "& .MuiMenuItem-root":{
    width:"100%"
  },
  "& .deleteitem":{
    color:"#DC2626",
    "& .option_list_item":{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      marginLeft:"7px",
    }
  },
  "& .menuItem":{
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    borderRadius:"5px",
    "& .option_list_item":{
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      marginLeft:"7px",
      color:"#0F172A"


    }
  }

});
const RightSection = styled(Box)({
  width:"338px",
  padding:"18px 0",
  background:"#FFFFFF",

  "& .right_header":{
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    gap:"8px",
  },
  "& .ADD_channale_member":{
    display:"flex",
    alignItems:"center",
    flexDirection:"column",
   
  },
  "& .channal_name":{
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
    marginTop:"43px",

  },
  "& .channal_member_no":{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    margin:"10px 0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color:"#334155"
  },
  "& .channal_leave":{
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color:"#DC2626",
    display: "flex",
    alignItems: "center",
    gap: "9px",
    cursor:'pointer',
  
  }
});
const Tab = styled(Box)({
  padding: "6px",
  display: "flex",
  justifyContent: "center",
  margin: "20px 0px",
  marginBottom:"10px",
  background: "#F3F7FB",
  borderRadius: "50px",
  color:"#E2E8F0",
  minWidth:"300px",

 
    "& .active":{
    background: "#D3E4E9",
      color:"#325962",
      borderRadius:"18px",
    padding: "6px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    border: "1px solid rgb(183, 198, 202)",
    minWidth:"87px",
    textAlign:"center"
  
    },
    "& .deactive":{
      color:"#64748B",
      borderRadius:"18px",
    padding: "6px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    border: "1px solid rgba(183, 198, 202, 0)",
     minWidth:"87px",
    textAlign:"center"

    }
  

});
const MoreOption = styled(Box)({
cursor:'pointer',
padding:"3px",
"&:hover": {
  background: "#D3E4E9",
  borderRadius: "50%",

},
})
const AddmemberIcon = styled(Box)({
  cursor:'pointer',
  padding:"3px",
  "&:hover": {
    background: "#D3E4E9",
    borderRadius: "8px",
  
  },
  })
  const Commonicondesign = styled(Box)({
    cursor:'pointer',
    padding:"3px",
    "&:hover": {
      background: "#D3E4E9",
      borderRadius: "8px",
    
    },
    })
    const ProjectchannalList = styled(Box)({
      borderRadius:'8px',
  padding: '6px 8px 6px 62px',
  fontWeight:600,
  fontSize:'14px',
  fontFamily:'Poppins',
  color:'#0F172A',
   display:'flex',
 alignItems:'center',
 gap:'5px',cursor:'pointer',
      "&:hover": {
        backgroundColor:'#F0F5F7',
        borderRadius: "8px",
      
      },
      })
      const NoMassage = styled(Box)({
textAlign:"center",
marginTop:"20px",
"& .nomsg_heading":{
  fontWeight:600,
  fontSize:'16px',
  fontFamily:'Poppins',
  lineHeight:"24px",
  color:"#1E293B",
},
"& .nomsg_subheading":{
  fontWeight:400,
  fontSize:'14px',
  fontFamily:'Poppins',
  lineHeight:"22px",
  color:"#475569",
}
      })

      const ProjectChatBar = styled(Box)({
        width:'22%',
        height:" calc(100vh - 114px)",
        padding:'16px 12px',
        backgroundColor:'white',
        borderRight: "1px solid #E2E8F0",
        overflow: 'auto',
        scrollbarWidth: "auto", 
        scrollbarColor:" #CBD5E1 #F1F5F9", 
      
         "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#CBD5E1",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F1F5F9",
        },
      })
      const Modellist = styled(Box)({
        maxHeight: "350px", overflowY: "auto", marginTop: "1rem",
        scrollbarWidth: "auto", 
        scrollbarColor:" #CBD5E1 #F1F5F9", 
        paddingRight:"10px",
      
         "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#CBD5E1",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F1F5F9",
        },
      })
      const CustomToast = styled(ToastContainer)({
        width: "40vw",
        "& .Toastify__toast": {
          boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
          padding: "0.5rem 1rem",
          borderRadius: "8px",
        },
        "& .Toastify__toast-body": {
          fontFamily: "Poppins",
          color: "#0F172A",
          padding: 0,
        },
      });
      const CustomInput = styled(Input)({
      "& .MuiInputBase-input::placeholder": {
    color: "#94A3B8",
    fontWeight:400,
    fontSize:'16px',
    fontFamily:'Poppins',
    lineHeight:"24px",
  },
      });
      




// Customizable Area End

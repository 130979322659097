import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string,
  password: string,
  confirmPassword: string,
  messageToggle:boolean,
  tostifymessage:string,
  status:string,
  validations: {
    [key: string]: boolean,
    emailValid: boolean,
    hasUpper: boolean,
    hasLower: boolean,
    hasSpecialChar: boolean,
    hasNumber: boolean,
    minLength: boolean,
    passwordsMatch: boolean,
  },
  errors: {
    email: string,
    password: string,
    confirmPassword: string,
    account:string,
    msg:string
  },
  isDisabled: boolean;
  originalPassword:string;
  originalPassword1:string;
  event:any;
  asterisk:string;
  asterisk1:string;
  showPassword:boolean
  showPassword1:boolean
  showSecondPart:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientEmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  setPasswordApiID :string =''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: '',
      password: '',
      confirmPassword: '',
      messageToggle:false,
      tostifymessage:"",
      status:"",
      validations: {
        emailValid: false,
        hasUpper: false,
        hasLower: false,
        hasSpecialChar: false,
        hasNumber: false,
        minLength: false,
        passwordsMatch: false,
      },
      errors: {
        email: '',
        password: '',
        confirmPassword: '',
        account: '',
        msg: ''
      },
      isDisabled: false,
      originalPassword:'',
      originalPassword1:'',
      event:"",      
      asterisk:"",
      asterisk1:"",
      showPassword:false,
      showPassword1:false,
      showSecondPart:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.setPasswordApiID) {
         if(responseJson?.message){
          this.setState({ messageToggle: true, tostifymessage: "Password set successfully", status: "success" }, () => {
            this.closeMessageBox();
          })
             this.handleLoginNavigate();
         }else{
          this.setState({ messageToggle: true, tostifymessage: "Something went wrong", status: "error" }, () => {
            this.closeMessageBox();
          })
         }
      };
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount () {
    super.componentDidMount();
    localStorage.removeItem("email");
    localStorage.removeItem("id");
  const query = new URLSearchParams(window.location.search);
  const email = query.get('email')??"";
  this.setState({email:email});
  this.validateEmail(email);
 }

 handleValidate = () => {
  const arr = ['emailValid', 'hasUpper', 'hasLower', 'hasSpecialChar', 'hasNumber', 'minLength', 'passwordsMatch'];
  return arr.map((obj:any) => this.state.validations[obj]).includes(false) || this.state.originalPassword !== this.state.originalPassword1;
 }

    validateConditions = () => {
      const {
        emailValid,
        hasUpper,
        hasLower,
        hasSpecialChar,
        hasNumber,
        minLength,
        passwordsMatch,
      } = this.state.validations;
  
      return emailValid && hasUpper && hasLower && hasSpecialChar && hasNumber &&
             minLength && passwordsMatch &&
             this.state.password === this.state.confirmPassword;
    };
    validateEmail = (email: string) => {
      const emailValid = configJSON.emailValidText.test(email);
      const error = emailValid ? '' : 'Please enter valid  email address.';
    
      this.setState({
        validations: { ...this.state.validations, emailValid },
        errors: { ...this.state.errors, email: error },
      });
    };
    handleContinue = (email:string) => {
      const emailValid = configJSON.emailValidText.test(email);
      const error = emailValid ? '' : 'Email is not valid';
      if (emailValid) {
        this.setState({ showSecondPart: true });
      } else {
        this.setState({
          validations: { ...this.state.validations, emailValid },
          errors: { ...this.state.errors, email: error },
        });
      }
    };
    
    handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const  {value}  = event.target;
    const { originalPassword } = this.state;
    const inputEvent = event.nativeEvent as InputEvent;
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart || 0;
  
    if (!inputEvent || !inputEvent.inputType || value === " ") {
      return;
    }
   
    let updatedPassword;
  
    if (inputEvent.inputType === 'insertText' && inputEvent.data) {
      const data = inputEvent.data;
      const originalArray = originalPassword.split('');
      originalArray.splice(cursorPosition-1,0,data); 
      updatedPassword = originalArray.join('');
      this.setState(
        {
          originalPassword: updatedPassword,
          asterisk: this.generateAsteriskString(updatedPassword),
          password: updatedPassword,
        },
        () => {
          const newCursorPosition = cursorPosition-1 + data.length;
          input.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      );
    }
    else if(!value){
      updatedPassword=value;
     }
     else{
       const originalArray = originalPassword.split('');
       originalArray.splice(cursorPosition, 1); 
       updatedPassword = originalArray.join('');
     }
     this.setState({
      originalPassword: updatedPassword,
      asterisk: this.generateAsteriskString(updatedPassword),
      password: updatedPassword,
     })
     this.validatePassword(updatedPassword);
    };
    
  validatePassword = (password: string) => {
    const hasUpper = configJSON.hasUpper.test(password);
    const hasLower = configJSON.hasLower.test(password);
    const hasSpecialChar = configJSON.hasSpecialChar.test(password);
    const hasNumber = configJSON.hasNumber.test(password);
    const minLength = password.length >= 8;
    const passwordsMatch = this.state.confirmPassword === '' || password === this.state.confirmPassword;
  
    const passwordError = hasUpper && hasLower && hasSpecialChar && hasNumber && minLength ? '' : 'Password is not valid';
    const confirmPasswordError = this.state.confirmPassword && !passwordsMatch ? "Passwords don't match" : '';
  
    this.setState({
      validations: {
        ...this.state.validations,
        hasUpper,
        hasLower,
        hasSpecialChar,
        hasNumber,
        minLength,
        passwordsMatch,
      },
      errors: {
        ...this.state.errors,
        password: passwordError,
        confirmPassword: confirmPasswordError,
      },
    });
  };
  
  handleChangePassword1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const  {value}  = event.target;
    const { originalPassword1 } = this.state;
    const inputEvent = event.nativeEvent as InputEvent;
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart || 0;
  
    if (!inputEvent || !inputEvent.inputType || value === " ") {
      return;
    }
   
    let updatedPassword;
  
    if (inputEvent.inputType === 'insertText' && inputEvent.data) {
      const data = inputEvent.data;
      const originalArray = originalPassword1.split('');
      originalArray.splice(cursorPosition-1,0,data); 
      updatedPassword = originalArray.join('');
      this.setState(
        {
          originalPassword1: updatedPassword,
          asterisk1: this.generateAsteriskString(updatedPassword),
          confirmPassword: updatedPassword,
        },
        () => {
          const newCursorPosition = cursorPosition-1 + data.length;
          input.setSelectionRange(newCursorPosition, newCursorPosition);
        }
      );
    }
    else if(!value){
      updatedPassword=value;
     }
     else{
       const originalArray = originalPassword1.split('');
       originalArray.splice(cursorPosition, 1); 
       updatedPassword = originalArray.join('');
     }
     this.setState({
      originalPassword1: updatedPassword,
      asterisk1: this.generateAsteriskString(updatedPassword),
      confirmPassword: updatedPassword,
     })
     this.validateConfirmPassword(updatedPassword);
  };

  generateAsteriskString = (password: string) => {
    return '*'.repeat(password.length);
  }
    
    validateConfirmPassword = (confirmPassword: string) => {
      const passwordsMatch = confirmPassword === this.state.originalPassword;
      const error = passwordsMatch ? '' : "Passwords don't match";
      this.setState({
        validations: { ...this.state.validations, passwordsMatch },
        errors: { ...this.state.errors, confirmPassword: error },
      });
    };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
    if (!this.validateConditions()) {
      return;
    }
    const header = {
      "Content-Type": "application/json",
    };
  
    const data = {
      
    };
  
    const httpBody = {
        email:this.state.email,
        new_password:this.state.password,
        confirm_password: this.state.confirmPassword
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setPasswordApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setPasswordUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }; 
  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  toggleVisibility1 = () => {
    this.setState(prevState => ({
      showPassword1: !prevState.showPassword1
    }));
  }

  closeMessageBox = () => {
    setTimeout(()=>{
     this.setState({messageToggle:false, tostifymessage:"", status:""})
    },1500);
  };
  handleLoginNavigate=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg);
  }
  // Customizable Area End
}

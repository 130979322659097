import React from "react";

import {

  Box,

  Button,

  Snackbar,

  Typography,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme} from "@material-ui/core/styles";
import MuiAlert from '@mui/material/Alert';
const loginbg = require("../assets/loginbg.jpg");
import { slider2} from "./assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";


export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getSnackbarStyle = (type: string) => {
    let backgroundColor;
  
    if (type === 'success') {
      backgroundColor = '#28a745';
    } else if (type === 'error') {
      backgroundColor = '#dc3545';
    } else if (type === 'info') {
      backgroundColor = '#007bff';
    } else {
      backgroundColor = '#343a40';
    }
  
    return {
      backgroundColor: backgroundColor,
      color: '#fff',
    };
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
  <>

      <Box style={webStyle.mainbox as React.CSSProperties} >
          <Box 
          style={{
            padding: "48px",
            height: "100vh",
            width: "50%",
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
            boxSizing: "border-box",
            textAlign: "center",
            backgroundImage: `url(${loginbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box>
            <Typography style={webStyle.logohereText}>
              Logo Here
            </Typography>
            <img src={slider2} style={webStyle.imgPic} />
            <Box paddingX={5}>
              <Typography style={webStyle.msgText}>
                Your new construction management tool.
              </Typography>
              <Typography style={webStyle.smallText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box data-test-id='oKay' style={webStyle.rightBox as React.CSSProperties} >
           <Box>
            <Box>
      <Snackbar
        open={this.state.showPopup}
        autoHideDuration={6000}
        onClose={this.handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          data-test-id="getSnackbarStyle"
          elevation={6}
          variant="filled"
          style={this.getSnackbarStyle(this.state.snackbarType)} 
          onClose={this.handleClose}
          severity={this.state.snackbarType}
        >
     {this.state.popupMessage}
        </MuiAlert>
      </Snackbar>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Typography gutterBottom style={webStyle.pleaseverifyText}>
                Please verify your email
              </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Typography style={webStyle.thankYouText} gutterBottom>
              Thank you for signing up. We've sent you an email to verify your account.
              </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Typography gutterBottom style={webStyle.thankYouText}>
               Please check your inbox and verify your account.
              </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Button data-test-id='okay' style={webStyle.signupTextStyle as React.CSSProperties} onClick={this.handleNavigate}>
                Log In
              </Button>
              </Box>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={"10px"}>
              <Typography style={{fontFamily: "'Poppins', sans-serif",color:'#334155',}}>
              Haven’t received ?
              </Typography>
              <Typography data-test-id="resend" onClick = {(e:any) => this.handleResendMail(e)} style={webStyle.resendEmailText}>
              {"  Resend mail"}
              </Typography>
              </Box>
            </Box>
           </Box>
        </Box>
      </Box>
      </>
     
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {       
  mainWrapper: { 
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  mainbox: {
    display: 'flex',
    height: '100vh',
    padding: 0,
    alignItems: "stretch",
    justifyContent: "stretch",
    backgroundColor: "rgba(50, 89, 98, 1)",
  },
  rightBox: {
    backgroundColor: "white",
    borderTopLeftRadius: "24px",
    borderBottomLeftRadius: "24px",
    padding: "48px",
    height: "100vh",
    overflowY: "auto",
    width: "50%",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    display:'flex'
  }  as const,
  leftbox: {
    padding: "48px",
    height: "100vh",
    width: "50%",
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
  }  as const,
  signupText: {
    ontWeight: "bold",
    textAlign: "center",
    color: "black",
    fontSize: "30px",
    paddingTop: "20px",
    paddingBottom: "20px"
  } as const,
  textStyle: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: "600"
  } as const,
  logohereText: {
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    textAlign: 'left'
  }  as const,
  msgText: {
    fontSize: "38px",
    color: "white",
    fontWeight: "bold",
    lineHeight: "1.2"
  } as const,
  smallText: {
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 100,
    fontSize: "18px",
    color: "white",
    width:"99%",
    marginTop:"9px"
  } as const,
  imgPic: {
    width: "400px"
  } as const,
  signupTextStyle: {
    marginTop: '10px',
    background: "#237182",
    borderRadius: "8px",
    fontWeight: "bold",
    color: "white",
    fontSize: "16px",
    textTransform: 'none',
    width:'440px',
    padding:"15px",
    fontFamily: "'Poppins', sans-serif",
  }  as const, 
  pleaseverifyText:{
    fontSize:'30px',
    color:'#0F172A',
    fontWeight:700,
    fontFamily: "'Poppins', sans-serif",
  },
  thankYouText:{
    fontSize:'15px',
    color:'#334155',
    fontFamily: "'Poppins', sans-serif",
  },
  resendEmailText:{
    marginLeft:'5px' ,
    fontSize:'18px' ,
    fontWeight:700,
    color:'#237182',
    fontFamily: "'Poppins', sans-serif",
    cursor:"pointer"
  }
};
// Customizable Area End
